import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Container,
  Header,
  Icon,
  Divider,
  Form,
  Input
} from "semantic-ui-react";
import {
  AppContainer,
  HeaderContainer,
  DocumentGrid
} from "../../app/common/styledComponents/Layout";
import { Block, EmptyState } from "../../app/common/styledComponents/Elements";
import Axios from "axios";
import NewsCard from "./NewsCard";
import styled from "styled-components";
import matchSorter from "match-sorter";
import { Ring } from "react-awesome-spinners";
import posed, { PoseGroup } from "react-pose";

const PosedCard = posed.div({
  enter: { opacity: 1, delay: ({ position }) => position * 100 },
  exit: { opacity: 0 }
});

const News = () => {
  const [articles, setArticles] = useState([]);
  const [searchValue, setSearch] = useState("");
  const [filterValue, setFilter] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [docConfig, setDocConfig] = useState({
    posts: [],
    baseUrl: `${process.env.REACT_APP_WP_API}/posts`,
    perPage: "?per_page=100"
  });

  // Get all of the posts from Wordpress
  useEffect(() => {
    async function getNumPages() {
      const { headers } = await Axios(
        `${docConfig.baseUrl}${docConfig.perPage}`
      );
      return headers["x-wp-totalpages"];
    }
    async function fetchArticles(numPages) {
      const posts = [];
      for (let page = 1; page <= numPages; page += 1) {
        const post = Axios.get(
          `${docConfig.baseUrl}${docConfig.perPage}&page=${page}`
        );
        posts.push(post);
      }
      await Axios.all(posts)
        .then(response => {
          const postData = response.map(res => res.data).flat();

          setArticles(prev => [...prev, ...postData]);
          setLoading(false);
        })
        .catch(e => console.log("error fetching posts: ", e));
      return true;
    }

    const fetchData = async () => {
      setLoading(true);
      getNumPages().then(numPages => fetchArticles(numPages));
    };
    fetchData();
  }, []);

  // Get all of the  categories from Wordpress
  useEffect(() => {
    const fetchData = async () => {
      const result = await Axios(`${process.env.REACT_APP_WP_API}/categories`);
      setAllCategories(result.data);
    };
    fetchData();
  }, []);

  const filteredData = matchSorter(articles, searchValue, {
    keys: [d => d.title.rendered]
  }).filter(d => {
    console.log("FILTER VALUE", filterValue);
    if (filterValue === "") {
      return d;
    }
    return d.categories.some(cat => {
      return cat.toString() === filterValue;
    });
  });

  // Create options for the dropdown list
  const CategoryOptions = () => {
    return allCategories.map(cat => {
      return (
        <option key={cat.id} value={cat.id}>
          {cat.name}
        </option>
      );
    });
  };

  return (
    <AppContainer>
      <Container>
        <HeaderContainer>
          <Header as='h2' color='orange' style={{ textTransform: "uppercase" }}>
            <Icon name='newspaper' size='mini' />
            <Header.Content>News</Header.Content>
          </Header>
        </HeaderContainer>
        <Block>
          <NewsTopBlock>
            <div className='ui form'>
              <Form>
                <Form.Field>
                  <label>Search</label>
                  <Input
                    icon='search'
                    value={searchValue || ""}
                    onChange={e => {
                      setSearch(e.target.value || "");
                      // Set undefined to remove the filter entirely
                    }}
                    placeholder={`Search ${articles.length} articles...`}
                  />
                </Form.Field>
              </Form>
            </div>
            <div className='ui form'>
              <Form>
                <Form.Field>
                  <label>Filter</label>
                  <select
                    name='category'
                    onChange={e => {
                      setFilter(e.target.value || "");
                    }}
                    value={filterValue || ""}
                  >
                    <option defaultValue value=''>
                      Filter by category
                    </option>
                    <CategoryOptions />
                  </select>
                </Form.Field>
              </Form>
            </div>
          </NewsTopBlock>

          <Divider />
          <DocContainer>
            {!isLoading ? (
              <DocumentGrid>
                <PoseGroup animateOnMount={true} flipMove={false}>
                  {filteredData.length > 0 ? (
                    filteredData.map((d, idx) => (
                      <PosedCard key={d.id} position={idx}>
                        <NewsCard article={d} key={d.id} />
                      </PosedCard>
                    ))
                  ) : (
                    <PosedCard
                      key='empty'
                      position={0}
                      style={{
                        gridColumn: "1 / 3 span"
                      }}
                    >
                      <EmptyState>
                        <Icon size='big' name='search' />
                        <Header as='h3'>No articles match your search</Header>
                      </EmptyState>
                    </PosedCard>
                  )}
                </PoseGroup>
              </DocumentGrid>
            ) : (
              <Ring color='#EB5B28' />
            )}
          </DocContainer>
        </Block>
      </Container>
    </AppContainer>
  );
};

export default News;

const DocContainer = styled.span`
  display: grid;
  justify-items: center;
`;

const ButtonLink = styled(NavLink)`
  background-color: #f2711c;
  color: #fff;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  /* float: right; */
  margin-right: 0;
  margin-left: 0.25em;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: all 0, 2s ease;
  :hover {
    background-color: #f26201;
    color: #fff;
  }
`;

const NewsTopBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  .buttonBox {
    text-align: right;
    margin-top: 5px;
  }
  select {
    height: 38px !important;
    color: rgba(0, 0, 0, 0.25) !important;
    font-size: 15px !important;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;
