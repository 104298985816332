import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import Axios from "axios";
import { NavLink } from "react-router-dom";
import Disqus from "disqus-react";
import { useToasts } from "react-toast-notifications";
import { useUserContext } from "../../app/App";

const DiscussionCard = ({ discussion, categories }) => {
  const [allCategories, setAllCategories] = useState([]);
  const [disqusConfig, setDisqusConfig] = useState(null);

  const disqusShortname = "uplifted-1"; //found in your Disqus.com dashboard

  const { addToast } = useToasts();
  const { userData } = useUserContext();

  useEffect(() => {
    setDisqusConfig({
      url: `${process.env.REACT_APP_DISQUS}/disussions/}`,
      // url: `http://localhost:3000/disussions/}`,
      identifier: discussion.id.toString(),
      title: discussion && discussion.title.rendered
    });
  }, [discussion, discussion.id, discussion.title.rendered]);

  useEffect(() => {
    const fetchData = async () => {
      categories.map(cat => {
        Axios.get(
          `${process.env.REACT_APP_WP_API}/categories_discussions/${cat}`
        ).then(res => {
          setAllCategories(prev => [...prev, res.data.name]);
        });
      });
    };
    fetchData();
  }, []);

  const Categories = ({ cats }) => {
    return cats.map(cat => {
      return <CategoryItem key={cat}>{cat}</CategoryItem>;
    });
  };

  const CommentsCount = ({ config }) => {
    return (
      <div>
        <Disqus.CommentCount
          shortname={disqusShortname}
          config={config}
        ></Disqus.CommentCount>{" "}
        <Icon name='comment outline' />
      </div>
    );
  };

  // Change discussion status to draft
  const deleteDiscussion = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Basic ZXZhbjpQQjVNcTdFb2lvbElEWFhKUzdTM0l5ZFA="
    };
    const body = {
      status: "draft"
    };

    Axios.post(
      `${process.env.REACT_APP_WP_API}/discussions/${discussion.id}`,
      body,
      {
        headers: headers
      }
    )
      .then(res => {
        addToast("Deleted Successfully", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 1500
        });
        setTimeout(() => {
          window.location.replace("/discussions");
        }, 1500);
      })
      .catch(function(error) {
        console.error("Error", error);
      });
  };

  return (
    <Card to={`/discussions/${discussion.id}`}>
      <DiscussionHeader>
        <h4>{`${discussion.created_by_key[0]}:`}</h4>
        <h3
          dangerouslySetInnerHTML={{
            __html: discussion.title.rendered
          }}
        />
        {userData.role && userData.role === "admin" && (
          <Icon
            onClick={deleteDiscussion}
            className='trashIcon'
            name='trash alternate outline'
          />
        )}

        <CategorySection>
          <Categories cats={allCategories} />
        </CategorySection>
      </DiscussionHeader>
      <CardContent>
        <p
          dangerouslySetInnerHTML={{
            __html: `${discussion.content.rendered.substr(3, 80)}...`
          }}
        />
      </CardContent>
      <CardFooter>
        <div className='left'>
          <NavButton to={`/discussions/${discussion.id}`}>Read More</NavButton>
        </div>
        <div className='comments'>
          {disqusConfig !== null && <CommentsCount config={disqusConfig} />}
        </div>
      </CardFooter>
    </Card>
  );
};

export default DiscussionCard;

const CategorySection = styled.div`
  margin-top: 20px;
  min-height: 40px;
`;

const DiscussionHeader = styled.div`
  position: relative;
  .trashIcon {
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.2s ease;
    :hover {
      cursor: pointer;
      color: #eb5b28;
    }
  }
  h4 {
    margin-bottom: 7px;
  }
  h3 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 20px;
    min-height: 75px;
  }
`;

const CardFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  .left {
  }
  .comments {
    text-align: right;
    i {
      margin-left: 3px;
      color: rgba(0, 0, 0, 0.87);
    }
    span {
      color: rgba(0, 0, 0, 0.87) !important;
    }
  }
`;

const CardContent = styled.div`
  p {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 20px;
    min-height: 50px;
  }
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 2em;
  margin-bottom: 2em;
  border: 1px solid #dedede;
  transition: all 0.3s ease;
  display: grid;
  grid-template-rows: auto auto 20px;
  height: 100%;
`;

const CategoryItem = styled.span`
  color: #fff;
  background-color: #eb5b28;
  padding: 5px 10px;
  font-weight: 600;
  margin-right: 5px;
  border-radius: 30px;
`;

const NavButton = styled(NavLink)`
  color: #eb5b28;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  transition: all 0, 2s ease;
  :hover {
    color: rgba(0, 0, 0, 0.87);
  }
`;
