import React from "react";
import styled from "styled-components";

const Label = styled.div`
	margin: 0.5em 0;
	padding: 0.4em 0.5em;
	border-radius: 15px;
	background-color: ${props => {
		switch (props.color) {
			case "orange":
				return "#EB5B28";
			case "purple":
				return "#8E2E88";
			case "green":
				return "#8CBF43";
			case "blue":
				return "#2570C0";
			case "teal":
				return "#43BFA1";

			default:
				return "#a9a9a9";
		}
	}};
	color: white;
	font-weight: 600;
	text-align: center;
	align-self: center;
	min-width: 60%;
`;

export default function ClassLabel({ classSlug }) {
	switch (classSlug) {
		case "younger-class":
			return <Label color="orange">Younger</Label>;
		case "older-class":
			return <Label color="purple">Older</Label>;
		case "babies-class":
			return <Label color="green">Babies</Label>;
		case "principals":
			return <Label color="blue">Principals</Label>;
		case "all":
			return <Label color="teal">All</Label>;

		default:
			return <Label color="grey">...</Label>;
	}
	return <div></div>;
}
