import React, { useMemo, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Icon, Popup } from "semantic-ui-react";
import {
	RoundButton,
	UsersTableContainer
} from "../../app/common/styledComponents/Elements";
import Table from "../../app/common/components/Table";
import RoleLabel from "../../app/common/components/RoleLabel";

export default function UsersTable({ firebase, approved }) {
	const [users, setUsers] = useState([]);

	useEffect(() => {
		const handleUserSnapshot = snap => {
			const usersList = snap.docs.map(doc => {
				const data = doc.data();
				return { id: doc.id, ...data };
			});
			setUsers(usersList);
		};
		const unsubscribe = firebase.users().onSnapshot(handleUserSnapshot);
		return () => unsubscribe();
	}, [firebase]);

	const userList = useMemo(
		() =>
			users
				.filter(u => !u.isArchived)
				.filter(u => u.isApproved === approved),
		[users, approved]
	);

	const columns = useMemo(
		() => [
			{
				Header: "Name",
				id: "name",
				accessor: u => `${u.firstName} ${u.lastName}`,
				filter: "fuzzyText"
			},
			{
				Header: "Contact Details",
				id: "email",
				accessor: u => u,
				Cell: cell => {
					return (
						<div>
							<p>
								<Icon name="mail" color="grey" />{" "}
								{cell.row.original.email}
							</p>
							<p>
								<Icon name="phone" color="grey" />{" "}
								{cell.row.original.phone}
							</p>
						</div>
					);
				},
				disableFilters: true
			},

			{
				Header: "EDC Centre",
				id: "centre",
				accessor: u => u.centre,
				filter: "fuzzyText"
			},
			{
				Header: "Role",
				id: "role",
				accessor: u => u.role,
				Cell: cell => {
					return <RoleLabel role={cell.row.original.role} />;
				},
				disableFilters: true
			},
			{
				Header: "Actions",
				accessor: u => u.id,
				Cell: cell => {
					return (
						<span>
							{cell.row.original.isApproved ? (
								<Popup
									size="mini"
									inverted
									content="Unapprove"
									trigger={
										<span>
											<RoundButton
												icon
												color="orange"
												onClick={() =>
													firebase.unApproveUser(
														cell.row.original.id
													)
												}
											>
												<Icon name="times" />
											</RoundButton>
										</span>
									}
								/>
							) : (
								<Popup
									size="mini"
									inverted
									content="Approve"
									trigger={
										<span>
											<RoundButton
												icon
												color="green"
												onClick={() =>
													firebase.approveUser(
														cell.row.original.id
													)
												}
											>
												<Icon name="check" />
											</RoundButton>
										</span>
									}
								/>
							)}
							<Popup
								size="mini"
								inverted
								content="Delete"
								trigger={
									<span>
										<RoundButton
											icon
											color="grey"
											onClick={() =>
												firebase.archiveUser(
													cell.row.original.id
												)
											}
										>
											<Icon name="trash" />
										</RoundButton>
									</span>
								}
							/>
							<Popup
								size="mini"
								inverted
								content="View"
								trigger={
									<NavLink
										to={`/members/${cell.row.original.id}`}
									>
										<RoundButton icon color="grey">
											<Icon name="eye" />
										</RoundButton>
									</NavLink>
								}
							/>
						</span>
					);
				},
				className: "actions",
				disableFilters: true
			}
		],
		[firebase]
	);
	return (
		<UsersTableContainer>
			<Table
				columns={columns}
				data={userList}
				defaultRows={10}
				emptyIcon="check"
				emptyMsg="No users to approve"
			/>
		</UsersTableContainer>
	);
}
