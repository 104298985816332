import React, { useMemo } from "react";
import { UsersTableContainer } from "../../app/common/styledComponents/Elements";
import Table from "../../app/common/components/Table";
import { formatDistanceToNow } from "date-fns";

export default function UserLocationTable({ locations }) {
	const sortedLocations = [
		...locations
			.filter(l => l.loginDate)
			.sort((a, b) => {
				return b.loginDate.toDate() - a.loginDate.toDate();
			}),
		...locations.filter(l => !l.loginDate)
	];
	const columns = useMemo(
		() => [
			{
				Header: "Login Date",
				id: "date",
				accessor: l =>
					l.loginDate
						? formatDistanceToNow(l.loginDate.toDate(), {
								addSuffix: true
						  })
						: "-",
				disableFilters: true
			},
			{
				Header: "City",
				id: "city",
				accessor: l => l.city,
				filter: "fuzzyText"
			},
			{
				Header: "Country",
				id: "country",
				accessor: l => l.country,
				filter: "fuzzyText"
			},
			{
				Header: "Zip Code",
				id: "zip",
				accessor: l => l.zip,
				filter: "fuzzyText"
			}
		],
		[]
	);
	return (
		<UsersTableContainer>
			<Table columns={columns} data={sortedLocations} defaultRows={5} />
		</UsersTableContainer>
	);
}
