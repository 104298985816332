import React from "react";
import { Header } from "semantic-ui-react";
import { format } from "date-fns";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import styled from "styled-components";
import { Block } from "../../../app/common/styledComponents/Elements";

const StyledDateRangePicker = styled(DateRangePicker)`
	.DateRangePicker__CalendarSelection {
		background-color: #eb5b28;
		border-color: #eb5b28;
	}
	.DateRangePicker__DateLabel {
		font-weight: 700;
	}
`;

const TermBlock = styled(Block)`
	border: 1px solid #dedede;
	padding: 1em;
`;

const TermItem = styled.div`
	display: grid;
	grid-template-columns: 325px auto;
	height: 275px;
`;

const WrittenDates = styled.div`
	margin-top: 3.2em;
	h4 {
		margin-bottom: 0.5em !important;
	}
`;

const DateText = styled.p`
	font-size: 1.2em;
	color: ${props => (props.grey ? "#c6c6c6" : "#000")};
`;

export default function DateSelection({ dates, selectDates, term }) {
	const stateDefinitions = {
		available: {
			color: null,
			label: "Available"
		}
	};
	const handleSelect = (range, states) => {
		// range is a moment-range object
		selectDates(range, states, term);
	};
	return (
		<TermBlock>
			<Header
				as="h4"
				dividing
				style={{
					textTransform: "uppercase"
				}}
			>
				{term.replace("term", "Term ")}
			</Header>
			<TermItem>
				<StyledDateRangePicker
					firstOfWeek={1}
					numberOfCalendars={1}
					selectionType="range"
					stateDefinitions={stateDefinitions}
					defaultState="available"
					showLegend={false}
					value={dates.value}
					onSelect={handleSelect}
				/>
				<WrittenDates>
					<Header as="h4">Start</Header>
					{dates.value && dates.value.start ? (
						<DateText>
							{format(dates.value.start.toDate(), "do MMMM")}
						</DateText>
					) : (
						<DateText grey>Please select a date</DateText>
					)}

					<Header as="h4">End</Header>
					{dates.value && dates.value.end ? (
						<DateText>
							{format(dates.value.end.toDate(), "do MMMM")}
						</DateText>
					) : (
						<DateText grey>Please select a date</DateText>
					)}
				</WrittenDates>
			</TermItem>
		</TermBlock>
	);
}
