import React, { useState, useEffect } from "react";
import { Block } from "../../app/common/styledComponents/Elements";
import { Container, Header, Icon, Divider } from "semantic-ui-react";
import { NavLink, withRouter } from "react-router-dom";
import {
  AppContainer,
  HeaderContainer
} from "../../app/common/styledComponents/Layout";
import styled from "styled-components";
import Axios from "axios";
import Disqus from "disqus-react";

const DiscussionSingle = ownProps => {
  const [discussion, setDiscussion] = useState([]);
  const [disqusConfig, setDisqusConfig] = useState(null);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [disqusShortname, setDisqusShortname] = useState("uplifted-1");

  // Get ID from URL
  const discussionId = ownProps.match.params.id;

  // Get the discussion
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_WP_API}/discussions/${discussionId}`
    ).then(res => {
      setDiscussion(...discussion, res.data);
      setTitle(res.data.title.rendered);
      setContent(res.data.content.rendered);
    });
  }, [discussionId]);

  useEffect(() => {
    setDisqusConfig({
      // url: `http://localhost:3000/disussions/${discussionId}`,
      url: `${process.env.REACT_APP_DISQUS}/disussions/${discussionId}`,
      identifier: discussionId,
      title: title
    });
  }, [discussion, discussionId, title]);

  const CommentsSection = ({ config }) => {
    return (
      <DisqusContainer>
        <Disqus.DiscussionEmbed shortname={disqusShortname} config={config} />
      </DisqusContainer>
    );
  };

  return (
    <AppContainer>
      <Container>
        <HeaderContainer>
          <Header as='h2' color='orange' style={{ textTransform: "uppercase" }}>
            <Icon name='newspaper' size='mini' />
            <Header.Content>Discussion</Header.Content>
          </Header>
        </HeaderContainer>
        <Block>
          <DiscussionHeader>
            <h1>{title}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html: content
              }}
            />
          </DiscussionHeader>
          <Divider />
          <DiscussionBody>
            {disqusConfig !== null && <CommentsSection config={disqusConfig} />}
          </DiscussionBody>
          <Divider />
          <DiscussionFooter>
            <BackButton to='/discussions'>Back to Discussions</BackButton>
          </DiscussionFooter>
        </Block>
      </Container>
    </AppContainer>
  );
};

export default withRouter(DiscussionSingle);

const DisqusContainer = styled.div``;

const DiscussionHeader = styled.div`
  max-width: 700px;
  margin: 30px auto;
  h1 {
    font-size: 38px;
  }
  @media (max-width: 800px) {
    margin: 10px auto;
    h1 {
      font-size: 24px;
    }
  }
`;

const DiscussionBody = styled.div`
  max-width: 700px;
  margin: 30px auto;
`;

const DiscussionFooter = styled.div`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
`;

const BackButton = styled(NavLink)`
  background-color: transparent;
  border: 2px solid #767676;
  /* border-color: #f2711c; */
  color: #767676;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  margin-right: 0;
  margin-left: 0.25em;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: all 0, 2s ease;
  :hover {
    border-color: #f26201;
    background-color: #f26201;
    color: #fff;
  }
`;
