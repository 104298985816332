import React, { useState, useEffect, useMemo } from "react";
import RoleLabel from "../../../app/common/components/RoleLabel";
import { Popup, Icon, Message, Button, Header } from "semantic-ui-react";
import {
	RoundButton,
	AdminViewTableContainer,
	EmptyState
} from "../../../app/common/styledComponents/Elements";
import Table from "../../../app/common/components/Table";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

export default function AdminViewTable({ firebase }) {
	const [users, setUsers] = useState([]);

	useEffect(() => {
		const handleUserSnapshot = snap => {
			const usersList = snap.docs.map(doc => {
				const data = doc.data();
				return { id: doc.id, ...data };
			});
			setUsers(usersList);
		};
		const unsubscribe = firebase.users().onSnapshot(handleUserSnapshot);
		return () => unsubscribe();
	}, [firebase]);

	const userList = useMemo(
		() => users.filter(u => !u.isArchived).filter(u => !u.isApproved),
		[users]
	);

	const columns = useMemo(
		() => [
			{
				Header: "Name",
				id: "name",
				accessor: u => `${u.firstName} ${u.lastName}`,
				filter: "fuzzyText"
			},
			{
				Header: "Role",
				id: "role",
				accessor: u => u.role,
				Cell: cell => {
					return <RoleLabel role={cell.row.original.role} />;
				},
				disableFilters: true
			},
			{
				Header: "Actions",
				accessor: u => u.id,
				Cell: cell => {
					return (
						<span>
							<Popup
								size="mini"
								inverted
								content="Approve"
								trigger={
									<span>
										<RoundButton
											icon
											color="green"
											onClick={() =>
												firebase.approveUser(
													cell.row.original.id
												)
											}
										>
											<Icon name="check" />
										</RoundButton>
									</span>
								}
							/>
							<Popup
								size="mini"
								inverted
								content="Delete"
								trigger={
									<span>
										<RoundButton
											icon
											color="grey"
											onClick={() =>
												firebase.archiveUser(
													cell.row.original.id
												)
											}
										>
											<Icon name="trash" />
										</RoundButton>
									</span>
								}
							/>
						</span>
					);
				},
				className: "actions",
				disableFilters: true
			}
		],
		[firebase]
	);

	return (
		<>
			<DesktopView>
				<AdminViewTableContainer>
					<Table
						emptyIcon="check"
						emptyMsg="No members to approve"
						columns={columns}
						data={userList}
						defaultRows={5}
						pagination={false}
					/>
				</AdminViewTableContainer>
			</DesktopView>
			<MobileView>
				{userList.length > 0 ? (
					userList.length === 1 ? (
						<div style={{ textAlign: "center" }}>
							<Icon size="big" name="check" />
							<Header as="h3">
								There is currently {userList && userList.length}{" "}
								member that needs approval.
							</Header>
							<NavLink to="/members">
								<Button color="orange">Approve Members</Button>
							</NavLink>
						</div>
					) : (
						<div style={{ textAlign: "center" }}>
							<Icon size="big" name="check" />
							<Header as="h3">
								There are currently{" "}
								{userList && userList.length} members that need
								approval.
							</Header>
							<NavLink to="/members">
								<Button color="orange">Approve Members</Button>
							</NavLink>
						</div>
					)
				) : (
					<EmptyState>
						<Icon size="big" name="check" />
						<Header as="h3">
							There are currently no members that need approval.
						</Header>
					</EmptyState>
				)}
			</MobileView>
		</>
	);
}

const DesktopView = styled.div`
	@media (max-width: 800px) {
		display: none;
	}
`;

const MobileView = styled.div`
	display: none;
	@media (max-width: 800px) {
		display: block;
	}
`;
