import React from "react";
import {
	AppContainer,
	HeaderContainer
} from "../../app/common/styledComponents/Layout";
import { Container, Header, Icon, Tab } from "semantic-ui-react";
import { Block } from "../../app/common/styledComponents/Elements";
import UsersTable from "./UsersTable";
import UsersCards from "./UsersCards";
import styled from "styled-components";

export default function Users({ firebase }) {
	const panes = [
		{
			menuItem: "Approved members",
			render: () => <UsersTable firebase={firebase} approved={true} />
		},
		{
			menuItem: "Members to approve",
			render: () => <UsersTable firebase={firebase} approved={false} />
		}
	];
	const mobilePanes = [
		{
			menuItem: "Approved members",
			render: () => <UsersCards firebase={firebase} approved={true} />
		},
		{
			menuItem: "Members to approve",
			render: () => <UsersCards firebase={firebase} approved={false} />
		}
	];
	return (
		<AppContainer>
			<Container>
				<HeaderContainer>
					<Header
						as="h2"
						color="orange"
						style={{ textTransform: "uppercase" }}
					>
						<Icon name="user" size="mini" />
						<Header.Content>Members</Header.Content>
					</Header>
				</HeaderContainer>
				<Block>
					<DesktopView>
						<Tab
							menu={{ secondary: true, pointing: true }}
							panes={panes}
						/>
					</DesktopView>
					<MobileView>
						<Tab
							menu={{ secondary: true, pointing: true }}
							panes={mobilePanes}
						/>
					</MobileView>
				</Block>
			</Container>
		</AppContainer>
	);
}

const DesktopView = styled.div`
	@media (max-width: 800px) {
		display: none;
	}
`;

const MobileView = styled.div`
	display: none;
	@media (max-width: 800px) {
		display: block;
	}
`;
