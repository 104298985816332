import React, { useState, useEffect } from 'react';
import { FirebaseContext } from './Firebase';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthRouter from '../features/auth/AuthRouter';
import { isWithinInterval } from 'date-fns';
import Axios from 'axios';
import { isEqual } from 'date-fns/esm';
import { ToastProvider } from 'react-toast-notifications';
import { Toast } from './common/components/Toast';
import Navbar from './common/navigation/Navbar';
import Footer from './common/components/Footer';

const AuthContext = React.createContext();
const UserContext = React.createContext();

const AuthProvider = ({ firebase, children }) => {
	const [authState, setAuthState] = useState({
		isLoading: true,
		user: undefined,
		terms: {},
		termReset: undefined,
		userId: undefined
	});
	const [userState, setUserState] = useState({
		isLoading: true,
		userData: undefined
	});

	useEffect(() => {
		const handleRedirect = async authUser => {
			if (
				!authUser &&
				!(
					window.location.pathname === '/' ||
					window.location.pathname === '/register'
				)
			) {
				window.location.replace('/');
			}

			if (
				authUser &&
				(window.location.pathname === '/' ||
					window.location.pathname === '/register')
			) {
				const fetchLocation = async () => {
					return firebase.addLocation({
						loginDate: new Date()
					});
				};
				return fetchLocation().then(data => {
					window.location.replace('/dashboard');
				});
			}
		};
		firebase.auth.onAuthStateChanged(authUser => {
			handleRedirect(authUser);
			if (authUser) {
				setAuthState(prev => ({
					...prev,
					user: authUser,
					userId: authUser.uid
				}));
			}
		});
	}, [firebase]);

	const handleUserSnapshot = snap => {
		setUserState(prev => ({ ...prev, userData: snap.data() }));
	};

	const handleTermSnapshot = snap => {
		const terms = snap.data();
		const now = new Date();
		const activeTerms = {};

		const reset =
			isEqual(terms.term1.start.toDate(), terms.term2.start.toDate()) &&
			isEqual(terms.term1.start.toDate(), terms.term3.start.toDate()) &&
			isEqual(terms.term1.start.toDate(), terms.term4.start.toDate());

		Object.keys(terms).map(key => {
			return (activeTerms[key] = isWithinInterval(now, {
				start: terms[key].start.toDate(),
				end: terms[key].end.toDate()
			}));
		});
		setAuthState(prev => ({
			...prev,
			terms: activeTerms,
			termReset: reset
		}));
	};

	useEffect(() => {
		if (!authState.userId) return;
		const unsubscribe = firebase
			.user(authState.userId)
			.onSnapshot(handleUserSnapshot);
		return () => unsubscribe();
	}, [authState.userId, firebase]);

	useEffect(() => {
		const unsubscribe = firebase
			.settings('terms')
			.onSnapshot(handleTermSnapshot);
		return () => unsubscribe();
	}, [authState.userId, firebase]);

	return (
		<UserContext.Provider value={userState}>
			<AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
		</UserContext.Provider>
	);
};

export const useAuthContext = () => React.useContext(AuthContext);
export const useUserContext = () => React.useContext(UserContext);

const App = () => {
	return (
		<ToastProvider components={{ Toast: Toast }}>
			<FirebaseContext.Consumer>
				{firebase => (
					<AuthProvider firebase={firebase}>
						<Router>
							{!(
								window.location.pathname === '/' ||
								window.location.pathname === '/register'
							) && <Navbar firebase={firebase} />}
							<AuthRouter firebase={firebase} />
							{!(
								window.location.pathname === '/' ||
								window.location.pathname === '/register'
							) && <Footer />}
						</Router>
					</AuthProvider>
				)}
			</FirebaseContext.Consumer>
		</ToastProvider>
	);
};

export default App;
