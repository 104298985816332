import React, { useState } from "react";
import { Header, Image, Button, Form } from "semantic-ui-react";
import { FullheightSplitGridForm } from "../../app/common/styledComponents/Layout";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import release from "../../app/config/release.json";

const HeroImage = styled.div`
	min-height: 100vh;
	height: 100%;
	background-image: url("/8.jpg");
	background-position: center;
	background-size: cover;
	display: grid;
	align-items: end;
	justify-items: end;
	img {
		margin: 2em 5%;
	}
`;

const FormBlock = styled.div`
	display: grid;
	grid-template-rows: auto 1fr 80px;
	align-items: flex-start;
	margin-top: 10em;
`;

const ButtonBlock = styled.div`
	margin-top: 2em;
`;

const ForgotPassword = ({ firebase }) => {
	const [error, setError] = useState();
	const [email, setEmail] = useState();
	const [sent, setSent] = useState(false);

	const onSubmit = async values => {
		firebase
			.doPasswordReset(email)
			.then(data => setSent(true))
			.catch(err => setError(err));
	};
	const handleChange = (evt, { value }) => {
		setEmail(value);
	};
	return (
		<FullheightSplitGridForm>
			<div className="content">
				<div className="logo-header">
					<Header
						as="h1"
						color="orange"
						textAlign="center"
						className="logo"
					>
						<Image src="/logo.svg" />
						UpliftED
					</Header>
					<Header as="h4" color="grey" textAlign="center">
						Teacher Training Portal
					</Header>
				</div>
				{sent ? (
					<FormBlock>
						<Header as="h2" color="grey" textAlign="center">
							Thank you
						</Header>
						<Header as="h3" color="grey" textAlign="center">
							You should receive a reset link at {email} shortly
						</Header>
					</FormBlock>
				) : (
					<Form onSubmit={onSubmit}>
						<FormBlock>
							<Header as="h2" color="grey" textAlign="center">
								Forgot password
							</Header>
							<Header as="h4" color="grey" textAlign="center">
								Enter your email address to get a reset link
							</Header>
							<Form.Input
								label="Email Address"
								type="text"
								name="email"
								onChange={handleChange}
								value={email}
								fluid
							/>

							<ButtonBlock>
								<Button
									floated="right"
									color="orange"
									size="large"
									type="submit"
								>
									Send Link
								</Button>
							</ButtonBlock>
							{error && (
								<div className="ui red message">
									<p>{error.message}</p>
								</div>
							)}
						</FormBlock>
					</Form>
				)}

				<div className="version">
					No account? <NavLink to="/register">Sign up</NavLink>
				</div>
				<div className="version">
					Have an account? <NavLink to="/">Login</NavLink>
				</div>
				<div className="version">Version: {release.versionClient}</div>
			</div>
			<div>
				<HeroImage>
					<Image src="/bot-logo-white.svg" size="small" />
				</HeroImage>
			</div>
		</FullheightSplitGridForm>
	);
};

export default ForgotPassword;
