import styled from "styled-components";

export const AppContainer = styled.div`
	background: #f0f0f0;
	padding: 2em 0;
	min-height: 100vh;
`;

export const AppFooter = styled.div`
	background: #f0f0f0;
	padding: 0 0 2em 0;
	.version {
		text-align: center;
		color: rgba(0, 0, 0, 0.5);
		font-size: 12px;
	}
	.grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: center;
		div {
			justify-self: center;
		}
		.left-img {
			text-align: left !important;
			justify-self: start;
			color: rgba(0, 0, 0, 0.5);
			font-size: 12px;
			img {
				margin: 1em 0;
				width: 200px;
			}
		}
		.right-img {
			justify-self: end;
			max-width: 150px;
		}
		@media (max-width: 800px) {
			display: block;
			.left-img,
			.right-img {
				display: none;
			}
		}
	}
`;

export const HeaderContainer = styled.div`
	margin-bottom: 2em;
	display: grid;
	grid-template-columns: 1fr 1fr;
	.backButtonBox {
		text-align: right;
		margin-top: 5px;
	}
`;

export const FullheightSplitGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	min-height: 100vh;
	height: 100%;
	.content {
		padding: 2em;
		width: 70%;
		margin: auto;
	}
	@media (max-width: 900px) {
		grid-template-columns: 1fr;
	}
	@media (max-width: 768px) {
		.content {
			padding: 2em;
			width: 90%;
		}
	}
`;

export const FullheightSplitGridForm = styled(FullheightSplitGrid)`
	.content {
		min-height: 100vh;
		height: 100%;
		display: grid;
		grid-template-rows: 150px 1fr 150px;
		.logo-header {
			align-self: end;
		}
	}
	.version {
		text-align: center;
		color: rgba(0, 0, 0, 0.5);
		font-size: 12px;
		margin-top: 0.5em;
	}
`;

export const HalfSplitGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 2em;
	@media (max-width: 800px) {
		grid-template-columns: 1fr;
	}
`;

export const DocumentGrid = styled.div`
	width: 100%;
	display: grid;
	margin: 2em 0 0;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 2em;
	grid-row-gap: 2em;
	@media (max-width: 700px) {
		grid-template-columns: 1fr;
	}
	@media (min-width: 700px) and (max-width: 1200px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;
