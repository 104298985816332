import React from "react";
import {
	AppContainer,
	HeaderContainer
} from "../../app/common/styledComponents/Layout";
import { Container, Header, Icon } from "semantic-ui-react";
import Details from "./Details";
import Password from "./Password";

export default function Profile({ firebase }) {
	return (
		<AppContainer>
			<Container>
				<HeaderContainer>
					<Header
						as="h2"
						color="orange"
						style={{ textTransform: "uppercase" }}
					>
						<Icon name="cog" size="mini" />
						<Header.Content>Profile Settings</Header.Content>
					</Header>
				</HeaderContainer>
				<Details firebase={firebase} />
				<Password firebase={firebase} />
			</Container>
		</AppContainer>
	);
}
