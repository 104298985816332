import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Image, Divider, Icon, Header } from "semantic-ui-react";
import Axios from "axios";
import {
	SeeAllLink,
	EmptyState
} from "../../app/common/styledComponents/Elements";
import styled from "styled-components";
import { formatDistanceToNow } from "date-fns";
import { Ring } from "react-awesome-spinners";

const NewsDashboard = ({ user }) => {
	const [article, setArticle] = useState();
	const [imageLink, setImageLink] = useState("");
	const [wpDate, setWpDate] = useState();
	const [title, setTitle] = useState();
	const [content, setContent] = useState();

	const [loading, setLoading] = useState(true);

	// Get the latest article from Wordpress
	useEffect(() => {
		Axios.get(`${process.env.REACT_APP_WP_API}/posts?per_page=1`).then(
			res => {
				res.data.map(item => {
					setArticle(item);
					setTitle(item.title.rendered);
					setContent(item.content.rendered);
				});
				setLoading(false);
			}
		);
	}, []);

	// Set date in correct format
	useEffect(() => {
		if (article && article.date) {
			setWpDate(new Date(article.date));
		}
	}, [article]);

	// Get Image from Id
	const Picture = ({ imageId }) => {
		Axios.get(`${process.env.REACT_APP_WP_API}/media/${imageId}`).then(
			res => {
				setImageLink(res.data.guid.rendered);
			}
		);
		return <Image src={imageLink} alt="post" />;
	};

	return !loading ? (
		article ? (
			<NewsBlock>
				<NewsItem>
					<TopBlock>
						{article.featured_media && (
							<Picture imageId={article.featured_media} />
						)}
						<div className="titleBox">
							{title && <h3>{title}</h3>}
							<span className="dateSection">
								Posted {wpDate && formatDistanceToNow(wpDate)}{" "}
								ago
							</span>
						</div>
					</TopBlock>
					<CardContent>
						{content && <p>{content.substr(4, 280)}...</p>}
						<NavButton to={`/news/${article.id}`}>
							Read More
						</NavButton>
					</CardContent>
				</NewsItem>
				<NewsFooter>
					<Divider />
					<SeeAllLink to="/news">See all</SeeAllLink>
				</NewsFooter>
			</NewsBlock>
		) : (
			<NewsItem>
				<EmptyState>
					<Icon size="big" name="newspaper outline" />
					<Header as="h3">There are no articles yet</Header>
				</EmptyState>
			</NewsItem>
		)
	) : (
		<NewsItem>
			<Ring color="#EB5B28" />
		</NewsItem>
	);
};

export default NewsDashboard;

const NewsItem = styled.div`
	height: 350px;
	@media (max-width: 800px) {
		height: auto;
	}
	display: grid;
	justify-items: center;
`;
const NewsFooter = styled.div``;

const TopBlock = styled.div`
	display: grid;
	grid-template-columns: 1.5fr 2fr;
	position: relative;
	.titleBox {
		padding: 20px;
	}
	.floatingCategory {
		position: absolute;
		top: 10px;
		right: 5px;
	}
	@media (max-width: 800px) {
		grid-template-columns: 1fr;
	}
`;

const CardContent = styled.div`
	margin-top: 20px;
	h3 {
		font-size: 22px;
	}
	p {
		font-size: 16px;
		color: rgba(0, 0, 0, 0.87);
		margin-bottom: 20px;
		min-height: 50px;
		line-height: 1.6;
	}
	.dateSection {
		margin-bottom: 20px !important;
		display: block;
	}
`;

const NewsBlock = styled.div``;

const NavButton = styled(NavLink)`
	color: #eb5b28;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 20px;
	transition: all 0, 2s ease;
	:hover {
		color: rgba(0, 0, 0, 0.87);
	}
`;
