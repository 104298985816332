import React, { useEffect, useState, useMemo } from "react";
import { Header } from "semantic-ui-react";
import Table from "../../../app/common/components/Table";
import { CentresTableContainer } from "../../../app/common/styledComponents/Elements";

export default function CentreList({ firebase }) {
	const [centres, setCentres] = useState([]);
	useEffect(() => {
		const handleUsersSnapshot = snap => {
			const centresList = snap.docs.map(doc => {
				const data = doc.data();

				return { id: doc.id, name: data.centre };
			});
			setCentres(centresList);
		};
		const unsubscribe = firebase.users().onSnapshot(handleUsersSnapshot);
		return () => unsubscribe();
	}, [firebase]);

	const data = useMemo(() => centres.filter(c => c.name), [centres]);

	const columns = useMemo(
		() => [
			{
				Header: "Name",
				id: "name",
				accessor: c => `${c.name ? c.name : "-"}`,
				filter: "fuzzyText"
			}
		],
		[]
	);
	return (
		<div>
			<Header
				as="h4"
				dividing
				style={{
					textTransform: "uppercase"
				}}
			>
				List of centres
			</Header>
			<CentresTableContainer>
				<Table columns={columns} data={data} defaultRows={20} />
			</CentresTableContainer>
		</div>
	);
}
