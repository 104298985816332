import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
	AppContainer,
	HeaderContainer
} from "../../app/common/styledComponents/Layout";
import { Container, Header, Icon } from "semantic-ui-react";
import { Block } from "../../app/common/styledComponents/Elements";
import UserLocationTable from "./UserLocationTable";
import styled from "styled-components";
import RoleLabel from "../../app/common/components/RoleLabel";
import ClassLabel from "../../app/common/components/ClassLabel";

const DetailsGridContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
`;

const DetailsGrid = styled.div`
	display: grid;
	grid-template-columns: 150px 1fr;
	h4,
	p {
		margin: 1em 0 !important;
	}
	span {
		align-self: center;
	}
	div {
		min-width: 0px;
		width: 150px;
		display: inline-block;
		margin-right: 10px;
	}
	ul {
		padding-inline-start: 20px;
	}
`;

function UserView({ match, firebase }) {
	const [user, setUser] = useState({});
	const [locations, setLocations] = useState([]);
	const userId = match.params.id;

	const handleUserSnapshot = snap => {
		setUser(prev => ({ ...prev, ...snap.data() }));
	};

	useEffect(() => {
		const unsubscribe = firebase
			.user(userId)
			.onSnapshot(handleUserSnapshot);
		return () => unsubscribe();
	}, [firebase, userId]);

	const handleLocationSnapshot = snap => {
		const locationsList = snap.docs.map(doc => {
			const data = doc.data();
			return { id: doc.id, ...data };
		});
		setLocations(locationsList);
	};

	useEffect(() => {
		const unsubscribe = firebase
			.locations(userId)
			.onSnapshot(handleLocationSnapshot);
		return () => unsubscribe();
	}, [firebase, userId]);

	const classList = classes => {
		if (classes && classes.length > 0) {
			return (
				<span>
					{classes.map((c, idx) => (
						<ClassLabel classSlug={`${c}-class`} key={idx} />
					))}
				</span>
			);
		} else return <p>-</p>;
	};
	const interestList = interests => {
		if (interests && interests.length > 0) {
			return (
				<ul>
					{interests.map((i, idx) => (
						<li key={idx}>{i}</li>
					))}
				</ul>
			);
		} else return <p>-</p>;
	};

	return (
		<AppContainer>
			<Container>
				<HeaderContainer>
					<Header
						as="h2"
						color="orange"
						style={{ textTransform: "uppercase" }}
					>
						<Icon name="user" size="mini" />
						<Header.Content>
							{user && user.displayName ? user.displayName : ""}
						</Header.Content>
					</Header>
				</HeaderContainer>
				<Block>
					<Header
						as="h3"
						dividing
						style={{ textTransform: "uppercase" }}
					>
						Members details
					</Header>
					<DetailsGridContainer>
						<DetailsGrid>
							<Header as="h4">Email</Header>
							<p>{user && user.email ? user.email : "-"}</p>
							<Header as="h4">Phone Number</Header>
							<p>{user && user.phone ? user.phone : "-"}</p>
							<Header as="h4">EDC Centre</Header>
							<p>{user && user.centre ? user.centre : "-"}</p>
						</DetailsGrid>
						<DetailsGrid>
							<Header as="h4">Role</Header>
							<RoleLabel role={user.role} />
							<Header as="h4">Classes</Header>
							{classList(user.classes)}
							<Header as="h4">Interests</Header>
							{interestList(user.interests)}
						</DetailsGrid>
					</DetailsGridContainer>

					<Header
						as="h3"
						dividing
						style={{ textTransform: "uppercase" }}
					>
						Location Log
					</Header>
					<UserLocationTable locations={locations} />
				</Block>
			</Container>
		</AppContainer>
	);
}

export default withRouter(UserView);
