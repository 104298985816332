import React, { useEffect, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Button, Divider, Icon, Header } from "semantic-ui-react";
import Axios from "axios";
import {
  SeeAllLink,
  EmptyState
} from "../../app/common/styledComponents/Elements";
import styled from "styled-components";
import { Ring } from "react-awesome-spinners";
import Disqus from "disqus-react";

const DiscussionDashboard = ({ user }) => {
  const [data, setData] = useState([]);
  //   const [searchValue, setSearch] = useState("");
  //   const [filterValue, setFilter] = useState("");
  //   const [allCategories, setAllCategories] = useState([]);
  //   const [disqusConfig, setDisqusConfig] = useState(null);

  const [loading, setLoading] = useState(true);

  const disqusShortname = "uplifted-1"; //found in your Disqus.com dashboard

  // Get all of the discussions from Wordpress
  useEffect(() => {
    const fetchData = async () => {
      const result = await Axios(
        `${process.env.REACT_APP_WP_API}/discussions?per_page=9`
      );
      setData([...result.data]);
      setLoading(false);
    };
    fetchData();
  }, []);

  console.log("Data", data);

  const Discussions = ({ data }) => {
    return data.map(d => {
      return (
        <DiscussionItem to={`/discussions/${d.id}`} key={d.id}>
          {/* <Icon name='comment outline' />  */}
          <p>{d.title.rendered}</p>
          {d !== null && (
            <CommentsCount
              config={{
                url: `${process.env.REACT_APP_DISQUS}/disussions/}`,
                identifier: d.id.toString(),
                title: d && d.title.rendered
              }}
            />
          )}
        </DiscussionItem>
      );
    });
  };

  const CommentsCount = ({ config }) => {
    return (
      <div>
        <Disqus.CommentCount
          shortname={disqusShortname}
          config={config}
        ></Disqus.CommentCount>{" "}
        <Icon name='comment outline' />
      </div>
    );
  };

  return (
    <DiscussionsBlock>
      {!loading ? (
        data.length > 0 ? (
          <DiscussionList items={data.length}>
            <Discussions data={data} />
            <div className='filler'></div>
          </DiscussionList>
        ) : (
          <EmptyState>
            <Icon size='big' name='comment outline' />
            <Header as='h3'>There are no discussions yet</Header>
          </EmptyState>
        )
      ) : (
        <Ring color='#EB5B28' />
      )}

      <SeeAllBlock>
        <Divider />
        <SeeAllLink to='/discussions'>See all</SeeAllLink>
      </SeeAllBlock>
    </DiscussionsBlock>
  );
};

export default DiscussionDashboard;

const DiscussionItem = styled(NavLink)`
  display: grid;
  grid-template-columns: auto 40px;
  column-gap: 1em;
  border-bottom: 1px solid rgba(74, 74, 74, 0.1);
  padding: 1rem 0.5rem;
  font-size: 14px;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    /* overflow: hidden; */
  }
`;

const DiscussionsBlock = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 87px;
  /* justify-items: center; */
  .filler {
  }
`;

const DiscussionList = styled.div`
  display: grid;
  height: 100%;
  --numItems: ${props => props.items};
  grid-template-rows: repeat(var(--numItems), 50px) auto;
`;

const SeeAllBlock = styled.div`
  width: 100%;
`;
