import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export const firebaseEnv = process.env.REACT_APP_ENV;
var firebaseConfig = {
	appName: "Botshabelo Uplifted Portal",
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DB_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_ID
};

class Firebase {
	constructor() {
		app.initializeApp(firebaseConfig);

		this.auth = app.auth();
		this.db = app.firestore();
	}

	// AUTH FUNCTIONS

	doCreateUserWithEmailAndPassword = (email, password) =>
		this.auth.createUserWithEmailAndPassword(email, password);

	doSignInWithEmailAndPassword = (email, password) =>
		this.auth.signInWithEmailAndPassword(email, password);

	doSignOut = () => this.auth.signOut();

	doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

	doPasswordUpdate = password =>
		this.auth.currentUser.updatePassword(password);

	doUpdateDisplayName = displayName => {
		return this.auth.currentUser.updateProfile({
			displayName: displayName
		});
	};

	// DATA FUNCTIONS
	settings = doc => this.db.doc(`settings/${doc}`);

	user = uid => this.db.doc(`users/${uid}`);

	users = () => this.db.collection("users");

	locations = uid => this.db.collection(`users/${uid}/locationLog`);

	centre = uid => this.db.doc(`centres/${uid}`);
	centres = () => this.db.collection(`centres`);

	addFavourite = docId => {
		return this.db.doc(`users/${this.auth.currentUser.uid}`).update({
			favouriteDocs: app.firestore.FieldValue.arrayUnion(docId)
		});
	};

	removeFavourite = docId => {
		return this.db.doc(`users/${this.auth.currentUser.uid}`).update({
			favouriteDocs: app.firestore.FieldValue.arrayRemove(docId)
		});
	};

	approveUser = user_id => {
		return this.db.doc(`users/${user_id}`).update({
			isApproved: true
		});
	};

	unApproveUser = user_id => {
		return this.db.doc(`users/${user_id}`).update({
			isApproved: false
		});
	};

	archiveUser = user_id => {
		return this.db.doc(`users/${user_id}`).update({
			isArchived: true
		});
	};

	archiveCentre = centre_id => {
		return this.db.doc(`centres/${centre_id}`).update({
			isArchived: true
		});
	};

	removeFavourite = docId => {
		return this.db.doc(`users/${this.auth.currentUser.uid}`).update({
			favouriteDocs: app.firestore.FieldValue.arrayRemove(docId)
		});
	};

	addLocation = location => {
		return this.db
			.doc(`users/${this.auth.currentUser.uid}`)
			.collection("locationLog")
			.add(location);
	};
}
export default Firebase;
