import React, { useMemo, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Icon, Popup, Divider, Form, Input, Header } from "semantic-ui-react";
import {
	RoundButton,
	EmptyState
} from "../../app/common/styledComponents/Elements";
import RoleLabel from "../../app/common/components/RoleLabel";
import styled from "styled-components";
import matchSorter from "match-sorter";

export default function UsersCards({ firebase, approved }) {
	const [users, setUsers] = useState([]);
	const [searchValue, setSearch] = useState("");

	useEffect(() => {
		const handleUserSnapshot = snap => {
			const usersList = snap.docs.map(doc => {
				const data = doc.data();
				return { id: doc.id, ...data };
			});
			setUsers(usersList);
		};
		const unsubscribe = firebase.users().onSnapshot(handleUserSnapshot);
		return () => unsubscribe();
	}, [firebase]);

	const userList = useMemo(
		() =>
			users
				.filter(u => !u.isArchived)
				.filter(u => u.isApproved === approved),
		[users, approved]
	);

	const Cards = ({ users }) => {
		const filteredData = matchSorter(users, searchValue, {
			keys: [
				d => d.displayName,
				d => d.email,
				d => d.fistName,
				d => d.lastName,
				d => d.centre,
				d => d.role
			]
		});
		return filteredData.length > 0 ? (
			filteredData.map((user, idx) => {
				return (
					<Card key={user.id}>
						<div className="cardHeader">
							<h3>{user.displayName}</h3>
							<RoleLabel role={user.role} />
						</div>
						<Divider />
						<p>
							<strong>
								<Icon name="mail" color="grey" />:{" "}
							</strong>
							{user.email}
						</p>
						<p>
							<strong>
								<Icon name="phone" color="grey" />:{" "}
							</strong>
							{user.phone}
						</p>
						<p>
							<strong>Centre: </strong>{" "}
							{user.centre && user.centre}
						</p>

						<span>
							{user.isApproved ? (
								<Popup
									size="mini"
									inverted
									content="Unapprove"
									trigger={
										<span>
											<RoundButton
												icon
												color="orange"
												onClick={() =>
													firebase.unApproveUser(
														user.id
													)
												}
											>
												<Icon name="times" />
											</RoundButton>
										</span>
									}
								/>
							) : (
								<Popup
									size="mini"
									inverted
									content="Approve"
									trigger={
										<span>
											<RoundButton
												icon
												color="green"
												onClick={() =>
													firebase.approveUser(
														user.id
													)
												}
											>
												<Icon name="check" />
											</RoundButton>
										</span>
									}
								/>
							)}
							<Popup
								size="mini"
								inverted
								content="Delete"
								trigger={
									<span>
										<RoundButton
											icon
											color="grey"
											onClick={() =>
												firebase.archiveUser(user.id)
											}
										>
											<Icon name="trash" />
										</RoundButton>
									</span>
								}
							/>
							<Popup
								size="mini"
								inverted
								content="View"
								trigger={
									<NavLink to={`/members/${user.id}`}>
										<RoundButton icon color="grey">
											<Icon name="eye" />
										</RoundButton>
									</NavLink>
								}
							/>
						</span>
					</Card>
				);
			})
		) : (
			<EmptyState style={{ gridColumn: "1 / 3 span" }}>
				<Icon size="big" name="user outline" />
				<Header as="h3">No users match your search</Header>
			</EmptyState>
		);
	};

	return (
		<UsersCardsContainer>
			<UsersTopBlock>
				<div className="ui form">
					<Form>
						<Form.Field>
							<label>Search</label>
							<Input
								icon="search"
								value={searchValue || ""}
								onChange={e => {
									setSearch(e.target.value || "");
									// Set undefined to remove the filter entirely
								}}
								placeholder={`Search`}
							/>
						</Form.Field>
					</Form>
				</div>
			</UsersTopBlock>
			<Divider />
			<Cards users={userList} />
		</UsersCardsContainer>
	);
}

const UsersCardsContainer = styled.div``;
const UsersTopBlock = styled.div``;

const Card = styled.div`
	background-color: #fff;
	border-radius: 10px;
	padding: 2em 1.8em;
	overflow: hidden;
	margin-bottom: 1.5em;
	border: 1px solid #dedede;
	transition: all 0.3s ease;
	.cardHeader {
		display: grid;
		grid-template-columns: 1.5fr 1fr;
		align-items: center;
		h3 {
			margin-bottom: 0px;
		}
	}
`;
