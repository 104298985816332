import React from "react";
import styled from "styled-components";

const IconWrapper = styled.div`
  border-radius: 4px;
  padding: 23px 30px 30px 30px;
  transition: all 0.2s ease-in-out;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999999;
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;

const Icon = styled.div`
  float: right;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 30px;
  height: 25px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #fff !important;
    border-radius: 10px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  span:nth-child(1) {
    top: 0px;
  }
  span:nth-child(2),
  span:nth-child(3) {
    top: 10px;
  }
  span:nth-child(4) {
    top: 20px;
  }
  &.open span:nth-child(1) {
    top: 10px;
    width: 0%;
    left: 50%;
    background: #fff;
  }
  &.open span:nth-child(2) {
    transform: rotate(45deg);
    background: #fff;
  }
  &.open span:nth-child(3) {
    transform: rotate(-45deg);
    background: #fff;
  }
  &.open span:nth-child(4) {
    top: 10px;
    width: 0%;
    left: 50%;
    background: #fff;
  }
`;

function NavIcon({ open, headerPinned }) {
  const classes = ["nav-icon"];
  if (open) {
    classes.push("open");
  }
  return (
    <IconWrapper>
      <Icon className={classes.join(" ")} headerPinned={headerPinned}>
        <span />
        <span />
        <span />
        <span />
      </Icon>
    </IconWrapper>
  );
}

export default NavIcon;
