import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Register from "./Registration";
import ThankYou from "./Registration/ThankYou";
import Login from "./Login";
import Dashboard from "../Dashboard";
import Documents from "../Documents";
import News from "../News";
import NewsSingle from "../News/NewsSingle";
import Discussions from "../Discussions";
import DiscussionForm from "../Discussions/DiscussionForm";
import DiscussionSingle from "../Discussions/DiscussionSingle";

import { useAuthContext, useUserContext } from "../../app/App";
import Users from "../Users";
import Error404 from "../../app/common/components/Error404";
import UserView from "../Users/UserView";
import Footer from "../../app/common/components/Footer";
import Settings from "../Settings";
import Profile from "../Profile";
import ForgotPassword from "./ForgotPassword";

const AuthRouter = ({ firebase }) => {
	const { user, isLoading } = useAuthContext();
	const { userData } = useUserContext();

	const approvedRouteData = [
		{ path: "/news", component: <News firebase={firebase} /> },
		{ path: "/news/:id", component: <NewsSingle firebase={firebase} /> },
		{
			path: "/discussions",
			component: <Discussions firebase={firebase} />
		},
		{
			path: "/discussions/create",
			component: <DiscussionForm firebase={firebase} />
		},
		{
			path: "/discussions/:id",
			component: <DiscussionSingle firebase={firebase} />
		},
		{ path: "/documents", component: <Documents firebase={firebase} /> },
		{
			path: "/profile-settings",
			component: <Profile firebase={firebase} />
		}
	];

	const approvedRoutes = () => {
		if (userData && userData.isApproved && !userData.isArchived) {
			return approvedRouteData.map((r, idx) => (
				<Route
					key={idx}
					exact
					path={r.path}
					render={() => r.component}
				/>
			));
		} else {
			return null;
		}
	};

	const adminRouteData = [
		{ path: "/members", component: <Users firebase={firebase} /> },
		{ path: "/members/:id", component: <UserView firebase={firebase} /> },
		{ path: "/settings", component: <Settings firebase={firebase} /> }
	];

	const adminRoutes = () => {
		if (
			userData &&
			userData.role &&
			userData.role === "admin" &&
			userData.isApproved &&
			!userData.isArchived
		) {
			return adminRouteData.map((r, idx) => (
				<Route
					key={idx}
					exact
					path={r.path}
					render={() => r.component}
				/>
			));
		} else {
			return null;
		}
	};
	return (
		<Switch>
			<Route
				exact
				path="/"
				render={() => (
					<Login
						firebase={firebase}
						user={user}
						isLoading={isLoading}
					/>
				)}
			/>
			<Route
				exact
				path="/register"
				render={() => (
					<Register
						firebase={firebase}
						user={user}
						isLoading={isLoading}
					/>
				)}
			/>
			<Route
				exact
				path="/thank-you"
				render={() => (
					<ThankYou
						firebase={firebase}
						user={user}
						isLoading={isLoading}
					/>
				)}
			/>
			<Route
				exact
				path="/reset-password"
				render={() => (
					<ForgotPassword firebase={firebase} isLoading={isLoading} />
				)}
			/>

			<Route
				exact
				path="/dashboard"
				render={() => (
					<Dashboard firebase={firebase} isLoading={isLoading} />
				)}
			/>
			{approvedRoutes()}
			{adminRoutes()}
			<Route exact render={() => <Error404 />} />
		</Switch>
	);
};

export default AuthRouter;
