import React from "react";
import PropTypes from "prop-types";
import { Form, FormSpy } from "react-final-form";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

const ButtonBlock = styled.div`
	margin-top: 1em;
`;

const FormBlock = styled.div`
	display: grid;
	grid-template-rows: 100px 1fr 80px;
	align-items: flex-start;
	.buttons {
		align-self: start;
	}
	.steps {
		align-self: center;
		.progressbar {
			counter-reset: step;
		}
		.progressbar li {
			list-style-type: none;
			width: 30%;
			float: left;
			font-size: 1em;
			position: relative;
			text-align: center;
			text-transform: uppercase;
			color: #d5d5d5;
			font-weight: bold;
		}
		.progressbar li:before {
			width: 35px;
			height: 35px;
			content: counter(step);
			counter-increment: step;
			line-height: 35px;
			border: 2px solid #d5d5d5;
			display: block;
			text-align: center;
			margin: 0 auto 10px auto;
			border-radius: 50%;
			background-color: white;
		}
		.progressbar li:after {
			width: 100%;
			height: 2px;
			content: "";
			position: absolute;
			background-color: #d5d5d5 !important;
			top: 17.5px;
			left: -50%;
			z-index: -1;
		}
		.progressbar li:first-child:after {
			content: none;
		}
		.progressbar li.active {
			color: #fff;
		}
		.progressbar li.active:before {
			background-color: #f2711c;
			border-color: #f2711c;
		}
		.progressbar li.active + li:after {
			background-color: #f2711c !important;
		}
	}
`;

export default class Wizard extends React.Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired
	};
	static Page = ({ children }) => children;

	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			values: props.initialValues || {}
		};
	}
	next = values => {
		this.setState(state => ({
			page: Math.min(state.page + 1, this.props.children.length - 1),
			values
		}));
	};

	previous = () => {
		this.setState(state => ({
			page: Math.max(state.page - 1, 0)
		}));
	};

	/**
	 * NOTE: Both validate and handleSubmit switching are implemented
	 * here because 🏁 Redux Final Form does not accept changes to those
	 * functions once the form has been defined.
	 */

	validate = values => {
		const activePage = React.Children.toArray(this.props.children)[
			this.state.page
		];
		return activePage.props.validate
			? activePage.props.validate(values)
			: {};
	};

	handleSubmit = values => {
		const { children, onSubmit } = this.props;
		const { page } = this.state;
		const isLastPage = page === React.Children.count(children) - 1;
		if (isLastPage) {
			return onSubmit(values);
		} else {
			this.next(values);
		}
	};

	render() {
		const { children, error } = this.props;
		const { page, values } = this.state;
		const activePage = React.Children.toArray(children)[page];
		const isLastPage = page === React.Children.count(children) - 1;
		return (
			<Form
				initialValues={values}
				validate={this.validate}
				onSubmit={this.handleSubmit}
			>
				{({ handleSubmit, submitting, values }) => (
					<form onSubmit={handleSubmit}>
						<FormSpy
							subscription={{ values: true, valid: true }}
							onChange={state => {
								const { values, valid } = state;
								this.props.exposeValues({ values, valid });
							}}
						/>
						<FormBlock>
							<div className="steps">
								<ul className="progressbar">
									<li
										className={page >= 0 ? "active" : ""}
									></li>
									<li
										className={page >= 1 ? "active" : ""}
									></li>
									<li
										className={page >= 2 ? "active" : ""}
									></li>
								</ul>
							</div>
							{activePage}
							<div className="buttons">
								<ButtonBlock>
									<Button
										color="grey"
										size="large"
										type="button"
										onClick={this.previous}
										disabled={page === 0}
									>
										Previous
									</Button>
									{!isLastPage && (
										<Button
											floated="right"
											color="orange"
											size="large"
											type="submit"
										>
											Next
										</Button>
									)}
									{isLastPage &&
										(submitting ? (
											<Button
												floated="right"
												color="orange"
												size="large"
												type="submit"
												loading
											>
												Submit
											</Button>
										) : (
											<Button
												floated="right"
												color="orange"
												size="large"
												type="submit"
											>
												Submit
											</Button>
										))}
								</ButtonBlock>
								{error && (
									<div className="ui red message">
										<p>{error.message}</p>
									</div>
								)}
							</div>
						</FormBlock>
					</form>
				)}
			</Form>
		);
	}
}
