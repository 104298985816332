import React from "react";
import { DefaultToast } from "react-toast-notifications";
import styled from "styled-components";

const StyledToast = styled(DefaultToast)`
	background-color: ${props => {
		switch (props.appearance) {
			case "success":
				return "#8CBF43";
			case "error":
				return "#db2828";
			case "info":
				return "#8e2e88";
			case "warning":
				return "#eb5b28";
			default:
				break;
		}
	}} !important;

	.react-toast-notifications__toast__icon-wrapper {
		background-color: rgba(0, 0, 0, 0.1);
	}

	.react-toast-notifications__toast__content {
		color: white;
	}

	.react-toast-notifications__toast__dismiss-button {
		color: white;
	}
	margin: 6em 1em;
`;

export const Toast = ({ children, ...props }) => (
	<StyledToast {...props}>{children}</StyledToast>
);
