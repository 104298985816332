import React, { useState, useEffect } from "react";
import { Image } from "semantic-ui-react";
import styled from "styled-components";
import Axios from "axios";
import { NavLink } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";

const NewsCard = ({ article }) => {
  const [allCategories, setAllCategories] = useState([]);
  const [imageLink, setImageLink] = useState("");
  const [wpDate, setWpDate] = useState();

  useEffect(() => {
    const fetchData = async () => {
      article.categories.map(cat => {
        Axios.get(`${process.env.REACT_APP_WP_API}/categories/${cat}`).then(
          res => {
            setAllCategories(prev => [...prev, res.data.name]);
            return null;
          }
        );
        return null;
      });
    };
    fetchData();
  }, [article.categories]);

  // Set date in correct format
  useEffect(() => {
    setWpDate(new Date(article.date));
  }, [article]);

  // Map Categories
  const Categories = ({ cats }) => {
    return cats.map(cat => {
      return (
        <CategoryItem className='floatingCategory' key={cat}>
          {cat}
        </CategoryItem>
      );
    });
  };

  // Get Image from Id
  const Picture = ({ imageId }) => {
    Axios.get(`${process.env.REACT_APP_WP_API}/media/${imageId}`).then(res => {
      setImageLink(res.data.guid.rendered);
    });
    return <Image src={imageLink} alt='post' />;
  };

  return (
    <Card>
      <TopBlock>
        <Picture imageId={article.featured_media} />
        <Categories cats={allCategories} />
      </TopBlock>

      <div className='content'>
        <CardContent>
          <h3
            dangerouslySetInnerHTML={{
              __html: article.title.rendered
            }}
          />
          <span className='dateSection'>
            Posted {wpDate && formatDistanceToNow(wpDate)} ago
          </span>
          <div
            dangerouslySetInnerHTML={{
              __html: `${article.content.rendered.substr(1, 80)}...`
            }}
          />
          <NavButton to={`/news/${article.id}`}>Read More</NavButton>
        </CardContent>
      </div>
    </Card>
  );
};

export default NewsCard;

const TopBlock = styled.div`
  position: relative;
  .floatingCategory {
    position: absolute;
    top: 10px;
    right: 5px;
  }
`;

const CardContent = styled.div`
  h3 {
    font-size: 22px;
  }
  p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 20px;
    min-height: 50px;
    line-height: 1.6;
  }
  .dateSection {
    margin-bottom: 20px !important;
    display: block;
  }
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  .content {
    padding: 2em 2.2em;
    h3 {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  overflow: hidden;
  border: 1px solid #dedede;
  transition: all 0.3s ease;
`;

const CategoryItem = styled.span`
  color: #fff;
  background-color: #eb5b28;
  padding: 5px 10px;
  font-weight: 600;
  margin-right: 5px;
  border-radius: 30px;
`;

const NavButton = styled(NavLink)`
  color: #eb5b28;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  transition: all 0, 2s ease;
  :hover {
    color: rgba(0, 0, 0, 0.87);
  }
`;
