import React, { useState } from "react";
import { Header, Image, Button } from "semantic-ui-react";
import { FullheightSplitGridForm } from "../../app/common/styledComponents/Layout";
import { NavLink } from "react-router-dom";
import { Field, Form } from "react-final-form";
import styled from "styled-components";
import TextInput from "../../app/common/form/AuthTextInput";
import release from "../../app/config/release.json";
import Axios from "axios";

const HeroImage = styled.div`
	min-height: 100vh;
	height: 100%;
	background-image: url("/8.jpg");
	background-position: center;
	background-size: cover;
	display: grid;
	align-items: end;
	justify-items: end;
	img {
		margin: 2em 5%;
	}
`;

const FormBlock = styled.div`
	display: grid;
	grid-template-rows: auto 1fr 80px;
	align-items: flex-start;
	margin-top: 10em;
`;

const ButtonBlock = styled.div`
	margin-top: 2em;
`;

const SignupBlock = styled.div`
	text-align: center;
	width: 80%;
	margin: auto;
`;

//const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/* const Error = ({ name }) => (
	<Field
		name={name}
		subscribe={{ touched: true, error: true }}
		render={({ meta: { touched, error } }) =>
			touched && error ? <span>{error}</span> : null
		}
	/>
); */

const required = value => (value ? undefined : "Required");
const mustBeLongerThan6 = ({ value }) => {
	return value.length < 6
		? "Password must be at least 6 characters"
		: undefined;
};
const composeValidators = (...validators) => value =>
	validators.reduce(
		(error, validator) => error || validator(value),
		undefined
	);

const Login = ({ firebase }) => {
	const [error, setError] = useState();

	const onSubmit = async values => {
		firebase
			.doSignInWithEmailAndPassword(
				values.email.value,
				values.password.value
			)
			.then(authUser => {
				const fetchLocation = async () => {
					return firebase.addLocation({
						loginDate: new Date()
					});
				};
				return fetchLocation().then(data => {
					window.location.replace("/dashboard");
				});
			})
			.catch(error => {
				console.log(error);
				setError(error);
			});
	};
	return (
		<FullheightSplitGridForm>
			<div className="content">
				<div className="logo-header">
					<Header
						as="h1"
						color="orange"
						textAlign="center"
						className="logo"
					>
						<Image src="/logo.svg" />
						UpliftED
					</Header>
					<Header as="h4" color="grey" textAlign="center">
						Teacher Training Portal
					</Header>
				</div>
				<Form
					onSubmit={onSubmit}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values
					}) => (
						<form onSubmit={handleSubmit}>
							<FormBlock>
								<Header as="h2" color="grey" textAlign="center">
									Login
								</Header>
								<div className="ui form">
									<Field
										name="email"
										validate={required}
										fluid
										placeholder="example@mail.com"
										label="E-mail address"
										component={TextInput}
									/>

									<Field
										name="password"
										validate={composeValidators(
											required,
											mustBeLongerThan6
										)}
										fluid
										label="Password"
										placeholder="••••••"
										type="password"
										component={TextInput}
									/>
								</div>
								<ButtonBlock>
									{submitting ? (
										<Button
											floated="right"
											color="orange"
											size="large"
											type="submit"
											loading
										>
											Login
										</Button>
									) : (
										<Button
											floated="right"
											color="orange"
											size="large"
											type="submit"
										>
											Login
										</Button>
									)}
								</ButtonBlock>
								{error && (
									<div className="ui red message">
										<p>{error.message}</p>
									</div>
								)}
							</FormBlock>
						</form>
					)}
				/>
				<SignupBlock>
					<p>
						If you are not a Member of the UpliftED Association,
						please sign up here
					</p>
					<NavLink to="/register">
						<Button color="orange" size="large">
							Sign up
						</Button>
					</NavLink>
				</SignupBlock>
				<div className="version">
					Forgot password?{" "}
					<NavLink to="/reset-password">Reset it</NavLink>
				</div>
				<div className="version">Version: {release.versionClient}</div>
			</div>
			<div>
				<HeroImage>
					<Image src="/bot-logo-white.svg" size="small" />
				</HeroImage>
			</div>
		</FullheightSplitGridForm>
	);
};

export default Login;
