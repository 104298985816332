import React, { useState, useEffect } from "react";

import "react-daterange-picker/dist/css/react-calendar.css";

import styled from "styled-components";
import { Button } from "semantic-ui-react";
import DateSelection from "./DateSelection";

import { useToasts } from "react-toast-notifications";

const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

const TermGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 1em;

	button {
		justify-self: end;
		grid-column: span 2;
	}
`;

export default function TermDates({ firebase }) {
	const { addToast } = useToasts();
	const [dates, setDates] = useState({
		term1: { value: { start: null, end: null } },
		term2: { value: { start: null, end: null } },
		term3: { value: { start: null, end: null } },
		term4: { value: { start: null, end: null } }
	});

	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		const handleTermSnapshot = snap => {
			const termList = snap.data();

			const formattedTerms = {};
			Object.keys(termList).forEach(
				(key, idx) =>
					(formattedTerms[key] = {
						value: moment.range(
							moment(termList[key].start.toDate()),
							moment(termList[key].end.toDate())
						)
					})
			);
			setDates(formattedTerms);
		};
		const unsubscribe = firebase
			.settings("terms")
			.onSnapshot(handleTermSnapshot);
		return () => unsubscribe();
	}, [firebase]);

	const handleSelect = (range, states, term) => {
		setDates(prev => ({
			...prev,
			[term]: {
				value: range,
				states: states
			}
		}));
	};

	const sendDates = () => {
		const reformattedDates = {};
		Object.keys(dates).forEach(
			(key, idx) =>
				(reformattedDates[key] = {
					start: dates[key].value.start.toDate(),
					end: dates[key].value.end.toDate()
				})
		);
		firebase
			.settings("terms")
			.set(reformattedDates)
			.then(terms => {
				addToast("Saved Successfully", {
					appearance: "success",
					autoDismiss: true,
					autoDismissTimeout: 2000
				});
			})
			.catch(error => {
				addToast(error.message, {
					appearance: "error",
					autoDismiss: true,
					autoDismissTimeout: 2000
				});
			});
	};
	return (
		<div>
			<TermGrid>
				{Object.keys(dates).map((key, idx) => (
					<DateSelection
						selectDates={handleSelect}
						dates={dates[key]}
						term={key}
						key={idx}
					/>
				))}
				<Button
					color="orange"
					onClick={() => {
						sendDates();
					}}
				>
					Set Dates
				</Button>
			</TermGrid>
		</div>
	);
}
