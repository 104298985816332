import React from "react";
import { Block } from "../../../app/common/styledComponents/Elements";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Header, Icon } from "semantic-ui-react";

const ActionGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 1em;
	margin-top: 2em;
	@media (max-width: 800px) {
		grid-template-columns: 1fr;
	}
`;

export const ActionBlock = styled.div`
	padding: 4em 1em;

	cursor: pointer;
	text-align: center;
	border-radius: 15px;
	font-weight: 700;
	font-size: 1.2em;
	background-color: ${props => {
		switch (props.color) {
			case "orange":
				return "#EB5B28";
			case "purple":
				return "#8E2E88";
			case "green":
				return "#8CBF43";
			case "teal":
				return "#43BFA1";

			default:
				return "#AEAEAE";
		}
	}};
	h3 {
		color: white !important;
		display: inline-block;
	}
	@media (max-width: 800px) {
		padding: 2em 1em;
		margin-bottom: 20px;
	}
`;

export default function AdminActions() {
	return (
		<Block>
			<Header
				as="h3"
				dividing
				style={{
					textTransform: "uppercase"
				}}
			>
				Actions
			</Header>
			<ActionGrid>
				<a
					href="https://admin.uplifted.org.za/wp-admin/edit.php?post_type=documents"
					target="_blank"
					rel="noopener noreferrer"
				>
					<ActionBlock color="green">
						<Header as="h3" textAlign="center">
							<Icon name="file outline" size="mini" />
							<Header.Content>Manage Documents</Header.Content>
						</Header>
					</ActionBlock>
				</a>
				<NavLink to="/members">
					<ActionBlock color="orange">
						<Header as="h3" textAlign="center">
							<Icon name="user outline" size="mini" />
							<Header.Content>Manage Members</Header.Content>
						</Header>
					</ActionBlock>
				</NavLink>
				<a
					href="https://admin.uplifted.org.za/wp-admin/edit.php"
					target="_blank"
					rel="noopener noreferrer"
				>
					<ActionBlock color="purple">
						<Header as="h3" textAlign="center">
							<Icon name="newspaper outline" size="mini" />
							<Header.Content>
								Manage News/Discussion
							</Header.Content>
						</Header>
					</ActionBlock>
				</a>
			</ActionGrid>
		</Block>
	);
}
