import React, { Component } from "react";
import { Field } from "react-final-form";
import TextInput from "../../../app/common/form/AuthTextInput";
import { Form, Header } from "semantic-ui-react";
import RadioInput from "../../../app/common/form/RadioInput";

export default class RegisterStep2 extends Component {
	classesError = () => {
		const { values } = this.props;
		return (
			!values.classes || (values.classes && values.classes.length === 0)
		);
	};
	render() {
		const { values } = this.props;
		return (
			<div className="ui form">
				<Header as="h2" color="grey" textAlign="center">
					Tell us more about you
				</Header>
				<Field
					name="centre"
					fluid
					placeholder="ECD Centre"
					label="ECD Centre you are working at (if applicable)"
					component={TextInput}
				/>

				<Form.Group inline>
					<label>Your role in the centre or previous role</label>
				</Form.Group>
				<Form.Group inline>
					<Field
						name="role"
						value="practitioner"
						type="radio"
						values={values}
						label="Practitioner"
						component={RadioInput}
					/>

					<Field
						name="role"
						type="radio"
						value="principal"
						values={values}
						label="Principal"
						component={RadioInput}
					/>
				</Form.Group>
				{values.role === "practitioner" && (
					<span>
						<Form.Group inline>
							<label>Classes you teach</label>
						</Form.Group>
						<div className="grouped fields">
							<div className="field">
								<div className="ui checkbox">
									<Field
										name="classes"
										component="input"
										label="Babies"
										type="checkbox"
										value="babies"
									/>
									<label>Babies</label>
								</div>
							</div>
							<div className="field">
								<div className="ui checkbox">
									<Field
										name="classes"
										component="input"
										label="Younger"
										type="checkbox"
										value="younger"
									/>
									<label>Younger</label>
								</div>
							</div>
							<div className="field">
								<div className="ui checkbox">
									<Field
										name="classes"
										component="input"
										label="Older"
										type="checkbox"
										value="older"
									/>
									<label>Older</label>
								</div>
							</div>
						</div>
					</span>
				)}
			</div>
		);
	}
}
