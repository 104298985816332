import React from "react";
import styled from "styled-components";

const Label = styled.div`
	padding: 0.4em 0.5em;
	border-radius: 15px;
	background-color: #a9a9a9;
	color: white;
	font-weight: 600;
	text-align: center;
	align-self: center;
	min-width: 60%;
`;

export default function TermLabel({ termSlug }) {
	switch (termSlug) {
		case "all-terms":
			return <Label>All terms</Label>;
		case "term-1":
			return <Label>Term 1</Label>;
		case "term-2":
			return <Label>Term 2</Label>;
		case "term-3":
			return <Label>Term 3</Label>;
		case "term-4":
			return <Label>Term 4</Label>;

		default:
			break;
	}
	return <div></div>;
}
