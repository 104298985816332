import React, { useEffect, useState } from "react";
import {
	Container,
	Header,
	Icon,
	Divider,
	Input,
	Form,
	Radio
} from "semantic-ui-react";
import {
	AppContainer,
	HeaderContainer,
	DocumentGrid
} from "../../app/common/styledComponents/Layout";
import {
	Block,
	DocFilterBlock,
	EmptyState,
	HoverButton
} from "../../app/common/styledComponents/Elements";
import Axios from "axios";
import DocumentCard from "./DocumentCard";
import styled from "styled-components";
import matchSorter from "match-sorter";
import { useAuthContext, useUserContext } from "../../app/App";
import { Ring } from "react-awesome-spinners";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import posed, { PoseGroup } from "react-pose";

const FilterGrid = styled.div`
	grid-template-columns: repeat(5, 1fr);
	display: grid;
	column-gap: 1em;
	row-gap: 2em;
	margin: 2em 0;
	align-items: center;
	span {
		grid-column: 1 / 3 span;
		align-self: center;
		.inline.fields {
			margin: 0 !important;
		}
	}
	form {
		grid-column: 4 / 2 span;
		align-self: center;
		text-align: right;
	}
	@media (max-width: 800px) {
		column-gap: 0.5em;
	}
`;

const DesktopView = styled.div`
	@media (max-width: 800px) {
		display: none;
	}
`;

const MobileView = styled.div`
	display: none;
	@media (max-width: 800px) {
		display: block;
	}
	.ui.radio.checkbox {
		margin-bottom: 1em;
	}
`;

const DocContainer = styled.span`
	display: grid;
	justify-items: center;
`;

const PosedCard = posed.div({
	enter: { opacity: 1, delay: ({ position }) => position * 100 },
	exit: { opacity: 0 }
});

const Documents = ({ firebase }) => {
	const [documents, setDocuments] = useState([]);
	const [searchValue, setSearch] = useState("");
	const [classValue, setClass] = useState("");
	const [termValue, setTerm] = useState("all");
	const [isLoading, setLoading] = useState(true);

	const [showFavouriteValue, setShowFavourite] = useState(false);

	const { terms } = useAuthContext();
	const { userData } = useUserContext();

	const [hideOnScroll, setHideOnScroll] = useState(true);

	useScrollPosition(
		({ prevPos, currPos }) => {
			const isShow = currPos.y > prevPos.y;
			if (isShow !== hideOnScroll) setHideOnScroll(isShow);
		},
		[hideOnScroll]
	);

	const [docConfig, setDocConfig] = useState({
		posts: [],
		baseUrl: `${process.env.REACT_APP_WP_API}/documents`,
		perPage: "?per_page=100",
		termIds: [6, 7, 8, 9, 19]
	});

	const handleScrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	};

	useEffect(() => {
		let termCount = 0;
		let termCounter = 0;
		async function getNumPages(term) {
			const { headers } = await Axios(
				`${docConfig.baseUrl}${docConfig.perPage}&school_term=${term}`
			);
			return headers["x-wp-totalpages"];
		}
		async function fetchDocuments(numPages, term) {
			const posts = [];
			for (let page = 1; page <= numPages; page += 1) {
				const post = Axios.get(
					`${docConfig.baseUrl}${docConfig.perPage}&school_term=${term}&page=${page}`
				);
				posts.push(post);
			}
			await Axios.all(posts)
				.then(response => {
					const postData = response.map(res => res.data).flat();
					termCounter++;

					setDocuments(prev => [...prev, ...postData]);

					if (termCounter === termCount) setLoading(false);
				})
				.catch(e => console.log("error fetching posts: ", e));
			return true;
		}
		const fetchData = async () => {
			setLoading(true);

			const allTerms =
				Object.values(terms).length > 0 ? { ...terms, all: true } : {};
			Object.values(allTerms).forEach(async (term, idx) => {
				if (term) {
					termCount++;
					getNumPages(docConfig.termIds[idx]).then(numPages =>
						fetchDocuments(numPages, docConfig.termIds[idx], idx)
					);
				}
			});
		};
		fetchData();
	}, [docConfig, terms]);

	const filteredData = matchSorter(documents, searchValue, {
		keys: [d => d.title.rendered]
	})
		.filter(d => d && d.acf && d.acf.document)
		.filter(d => {
			if (classValue === "") {
				return d;
			}
			return d.acf.ages && d.acf.ages.slug === classValue;
		})
		.filter(d => {
			if (termValue === "all") {
				return d;
			}
			return d.acf.term.slug === termValue;
		})
		.filter(d => {
			if (!showFavouriteValue) {
				return d;
			}
			return (
				userData.favouriteDocs && userData.favouriteDocs.includes(d.id)
			);
		})
		.sort((a, b) =>
			a.title.rendered !== b.title.rendered
				? a.title.rendered < b.title.rendered
					? -1
					: 1
				: 0
		);

	const termSelects = () => {
		if (terms.term1 || terms.term2 || terms.term3 || terms.term4) {
			return [
				<Form.Field>
					<Radio
						label="All"
						name="termValue"
						value="all"
						checked={termValue === "all"}
						onChange={(e, { value }) => {
							setTerm(value);
						}}
					/>
				</Form.Field>,
				...Object.keys(terms).map(key => {
					if (terms[key]) {
						return (
							<Form.Field key={key}>
								<Radio
									label={key.replace("term", "Term ")}
									name="termValue"
									value={key.replace("term", "term-")}
									checked={
										termValue ===
										key.replace("term", "term-")
									}
									onChange={(e, { value }) => {
										setTerm(value);
									}}
								/>
							</Form.Field>
						);
					}
				})
			];
		}
	};
	const classList = ["Babies", "Younger", "Older"];
	const colours = ["green", "orange", "purple"];

	const classBlocks = () => {
		return [
			<DocFilterBlock
				active={classValue === ""}
				color="teal"
				onClick={() => {
					setClass("");
				}}
			>
				All Docs
			</DocFilterBlock>,
			...classList.map((c, idx) => (
				<DocFilterBlock
					active={classValue === `${c.toLowerCase()}-class`}
					color={colours[idx]}
					onClick={() => {
						setClass(`${c.toLowerCase()}-class`);
					}}
				>
					{`${c} Class`}
				</DocFilterBlock>
			)),
			<DocFilterBlock
				active={classValue === "principals"}
				color="blue"
				onClick={() => {
					setClass("principals");
				}}
			>
				Principals
			</DocFilterBlock>
		];
	};

	const classSelects = () => {
		return [
			<Form.Field>
				<Radio
					label="All"
					name="classValue"
					value=""
					checked={classValue === ""}
					onChange={(e, { value }) => {
						setClass("");
					}}
				/>
			</Form.Field>,
			...classList.map(c => (
				<Form.Field>
					<Radio
						label={`${c}`}
						name="classValue"
						value={`${c.toLowerCase()}-class`}
						checked={classValue === `${c.toLowerCase()}-class`}
						onChange={(e, { value }) => {
							setClass(value);
						}}
					/>
				</Form.Field>
			)),
			<Form.Field>
				<Radio
					label="Principals"
					name="classValue"
					value="principals"
					checked={classValue === "principals"}
					onChange={(e, { value }) => {
						setClass("principals");
					}}
				/>
			</Form.Field>
		];
	};

	return (
		<AppContainer>
			<Container>
				<HeaderContainer>
					<Header
						as="h2"
						color="orange"
						style={{ textTransform: "uppercase" }}
					>
						<Icon name="file" size="mini" />
						<Header.Content>Documents</Header.Content>
					</Header>
				</HeaderContainer>
				<Block>
					<Form size="large">
						<Form.Field inline>
							<label>Search documents</label>
							<Input
								icon="search"
								value={searchValue || ""}
								onChange={e => {
									setSearch(e.target.value || "");
									// Set undefined to remove the filter entirely
								}}
								placeholder={`Search ${filteredData.length} documents...`}
							/>
						</Form.Field>
					</Form>

					<Divider />
					<DesktopView>
						<FilterGrid>
							{classBlocks()}
							<span>
								<Form size="large">
									<Form.Group inline>
										{termSelects()}
									</Form.Group>
								</Form>
							</span>
							<Form size="large">
								<Form.Field>
									<Radio
										slider
										label="Only show favourites"
										checked={showFavouriteValue}
										onChange={() => {
											setShowFavourite(
												!showFavouriteValue
											);
										}}
									/>
								</Form.Field>
							</Form>
						</FilterGrid>
					</DesktopView>
					<MobileView>
						<Form size="large">
							<Form.Group inline>
								<label>Select class</label>
							</Form.Group>
							<Form.Group>{classSelects()}</Form.Group>
							<Divider />

							<Form.Group inline>
								<label>Select term</label>
							</Form.Group>
							<Form.Group>{termSelects()}</Form.Group>
							<Divider />
							<Form.Field>
								<Radio
									slider
									label="Only show favourites"
									checked={showFavouriteValue}
									onChange={() => {
										setShowFavourite(!showFavouriteValue);
									}}
								/>
							</Form.Field>
						</Form>
					</MobileView>
					<Divider />
					<DocContainer>
						{!isLoading ? (
							<DocumentGrid key="1">
								<PoseGroup
									animateOnMount={true}
									flipMove={false}
								>
									{filteredData.length > 0 ? (
										filteredData.map((d, idx) => (
											<PosedCard
												key={d.id}
												position={idx}
											>
												<DocumentCard
													document={d}
													firebase={firebase}
													favourite={
														userData &&
														userData.favouriteDocs &&
														userData.favouriteDocs.includes(
															d.id
														)
													}
												/>
											</PosedCard>
										))
									) : (
										<PosedCard
											key="empty"
											position={0}
											style={{
												gridColumn: "1 / 3 span"
											}}
										>
											<EmptyState>
												{console.log(
													filteredData.length
												)}
												<Icon
													size="big"
													name="folder open outline"
												/>
												<Header as="h3">
													No documents match your
													search
												</Header>
											</EmptyState>
										</PosedCard>
									)}
								</PoseGroup>
							</DocumentGrid>
						) : (
							<Ring color="#EB5B28" />
						)}
					</DocContainer>
				</Block>
			</Container>
			{!hideOnScroll && (
				<HoverButton onClick={handleScrollToTop} icon>
					<Icon name="arrow up"></Icon>
				</HoverButton>
			)}
		</AppContainer>
	);
};

export default Documents;
