import React from "react";
import {
	Header,
	Icon,
	Container,
	Image,
	Divider,
	Message,
	Button
} from "semantic-ui-react";

import {
	AppContainer,
	HalfSplitGrid,
	HeaderContainer
} from "../../app/common/styledComponents/Layout";

import { NavLink } from "react-router-dom";
import { Block, SeeAllLink } from "../../app/common/styledComponents/Elements";
import DocumentTableBlock from "./DocumentTable/DocumentTableBlock";
import { useUserContext, useAuthContext } from "../../app/App";
import AdminViewTable from "./DashboardUsersTable/AdminViewTable";
//import PrincipalViewTable from "./DashboardUsersTable/PrincipalViewTable";
import AdminActions from "./AdminActions";
//import UsersTable from "../Users/UsersTable";
import DiscussionDashboard from "../Discussions/DiscussionDashboard";
import NewsDashboard from "../News/NewsDashboard";
import { Ring } from "react-awesome-spinners";

const Dashboard = ({ firebase }) => {
	const { userData } = useUserContext();
	const { terms, termReset } = useAuthContext();

	const activeTerms = Object.keys(terms).filter(key => terms[key]);
	const lastTerm = activeTerms.length - 1;
	const activeTermString = activeTerms[lastTerm]
		? activeTerms[lastTerm].replace("term", "Term ")
		: "";

	return (
		<AppContainer>
			{userData ? (
				<Container>
					<HeaderContainer>
						<Header
							as="h2"
							color="orange"
							style={{ textTransform: "uppercase" }}
						>
							<Icon name="home" size="mini" />
							<Header.Content>Dashboard</Header.Content>
						</Header>
					</HeaderContainer>
					<div>
						{userData.isArchived ? (
							<Block>
								<Image
									src="/clipboards.svg"
									size="medium"
									centered
								/>
								<h2 style={{ textAlign: "center" }}>
									Your account has been archived
								</h2>
								<h3 style={{ textAlign: "center" }}>
									if this is an error please contact
									example@mail.com
								</h3>
							</Block>
						) : !userData.isApproved ? (
							<Block>
								<Image
									src="/not_approved.svg"
									size="medium"
									centered
								/>
								<h2 style={{ textAlign: "center" }}>
									Your account has not yet been activated
								</h2>
								<h3 style={{ textAlign: "center" }}>
									Check back soon
								</h3>
							</Block>
						) : (
							<div>
								<HalfSplitGrid>
									<span>
										<Block>
											<Header
												as="h3"
												dividing
												style={{
													textTransform: "uppercase"
												}}
											>
												Welcome{" "}
												{userData &&
													userData.displayName}
											</Header>
											<Header as="h1">
												{activeTermString}
											</Header>
											{termReset &&
												userData &&
												userData.role === "admin" && (
													<Message color="orange">
														<Message.Header>
															Please note the term
															dates need to be
															reset
														</Message.Header>
														<p>
															If you do not reset
															the dates all
															content will be
															visible all year
														</p>
														<NavLink to="/settings">
															<Button color="orange">
																Set Dates
															</Button>
														</NavLink>
													</Message>
												)}
										</Block>
										{userData &&
										userData.role === "admin" ? (
											<Block>
												<Header
													as="h3"
													dividing
													style={{
														textTransform:
															"uppercase"
													}}
												>
													Members for approval
												</Header>
												<AdminViewTable
													firebase={firebase}
												/>
												<Divider />
												<SeeAllLink to="/members">
													See all
												</SeeAllLink>
											</Block>
										) : (
											<Block>
												<Header
													as="h3"
													dividing
													style={{
														textTransform:
															"uppercase"
													}}
												>
													News
												</Header>
												<NewsDashboard />
											</Block>
										)}
									</span>
									<Block>
										<Header
											as="h3"
											dividing
											style={{
												textTransform: "uppercase"
											}}
										>
											Discussions
										</Header>
										<DiscussionDashboard />
									</Block>
								</HalfSplitGrid>

								{/* {userData.role && userData.role === "principal" && (
                  <Block>
                    <Header
                      as='h3'
                      dividing
                      style={{
                        textTransform: "uppercase"
                      }}
                    >
                      Teachers at your centre{" "}
                    </Header>
                    <PrincipalViewTable firebase={firebase} approved={true} />
                  </Block>
                )} */}
								{userData && userData.role === "admin" ? (
									<AdminActions />
								) : (
									<DocumentTableBlock firebase={firebase} />
								)}
							</div>
						)}
					</div>
				</Container>
			) : (
				<Container textAlign="center">
					<Ring color="#EB5B28" />
				</Container>
			)}
		</AppContainer>
	);
};

export default Dashboard;
