import React from "react";
import { Form } from "semantic-ui-react";

const AuthTextInput = ({ input, meta, ...rest }) => {
	return (
		<Form.Input
			icon={
				meta.touched
					? meta.error
						? "times circle outline"
						: "check circle outline"
					: "circle outline"
			}
			error={meta.error && meta.touched}
			{...input}
			value={input.value.value}
			{...rest}
			onChange={(event, value) => input.onChange(value)}
		/>
	);
};
export default AuthTextInput;
