import React from "react";
import styled from "styled-components";
import { NavLink, withRouter } from "react-router-dom";
import { useUserContext } from "../../App";
import { Dropdown, Header, Icon } from "semantic-ui-react";

const NavWrapper = styled.nav`
	display: grid;
	grid-template-columns: 1fr;
	transition: all 0.2s ease-in-out;
	a {
		font-size: 20px;
		text-decoration: none;
		line-height: 1rem;
		text-transform: capitalize;
		color: #ffffffe6;
		font-weight: 600;
		padding: 16px;
		transition: all 0.5s ease-in-out;
		text-align: center;
		position: relative;
		:hover {
			color: #fff;
		}
	}
`;

const Item = styled.div`
	font-size: 20px;
	text-decoration: none;
	line-height: 1rem;
	text-transform: uppercase;
	color: #ffffffe6;
	font-weight: 600;
	padding: 16px;
	transition: all 0.5s ease-in-out;
	/* text-align: center; */
	position: relative;
	:hover {
		color: #fff;
	}
`;

const NavItemContainer = styled.div``;

const ProfileSection = styled.div`
	background-color: #eb5b28;
	display: grid;
	align-content: center;
	justify-content: center;
	padding: 50px 30px 30px 30px;
	border-bottom: 1px solid #fff;
	.logoBox {
		display: grid;
		grid-template-columns: 1fr 2fr;
		grid-gap: 10px;
		.imgBox {
			display: grid;
			justify-content: end;
			img {
				width: auto;
				opacity: 1;
				position: static !important;
				display: grid;
				height: 50px;
			}
		}
		h1 {
			color: #fff;
			margin-top: 5px;
		}
	}

	h2 {
		text-align: center;
		margin-bottom: 20px;
	}
	.profileMenu {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 20px;
		.profileItem {
			font-size: 15px;
			border-radius: 40px;
			border: 1px solid #fff;
			padding: 12px 20px;
			text-align: center;
		}
	}
`;

const MenuSection = styled.div`
	display: grid;
	padding: 70px 30px 30px 30px;
	max-width: 300px;
	margin: auto;
`;

const NavItem = styled.div``;

const PointerNavItem = styled(NavItem)`
	margin-bottom: 40px;
	i {
		margin-right: 20px;
	}
`;

const Navigation = ({ firebase, toggle }) => {
	const signOut = () => {
		firebase.doSignOut();
	};
	const { userData } = useUserContext();

	return (
		<NavWrapper>
			{userData && userData.isApproved && !userData.isArchived ? (
				<NavItemContainer
					items={
						userData && userData.role && userData.role === "admin"
							? 6
							: 5
					}
				>
					<ProfileSection>
						<div className="logoBox">
							<div className="imgBox">
								<img src="/logo-white.svg" alt="logo" />
							</div>
							<Header as="h1" className="logo">
								UpliftED
							</Header>
						</div>
						<h2>{userData && userData.displayName}</h2>
						<div className="profileMenu">
							<NavLink
								className="profileItem"
								to="/settings"
								onClick={toggle}
							>
								Settings
							</NavLink>
							<Item className="profileItem" onClick={signOut}>
								Logout
							</Item>
						</div>
					</ProfileSection>
					<MenuSection>
						<PointerNavItem>
							<NavLink to="/dashboard" onClick={toggle}>
								<Icon name="home" />
								Dashboard
							</NavLink>
						</PointerNavItem>
						<PointerNavItem>
							<NavLink to="/documents" onClick={toggle}>
								<Icon name="file" onClick={toggle} />
								Documents
							</NavLink>
						</PointerNavItem>
						<PointerNavItem>
							<NavLink to="/news" onClick={toggle}>
								<Icon name="newspaper" />
								News
							</NavLink>
						</PointerNavItem>
						<PointerNavItem>
							<NavLink to="/discussions" onClick={toggle}>
								<Icon name="newspaper" />
								Discussions
							</NavLink>
						</PointerNavItem>

						{userData &&
							userData.role &&
							userData.role === "admin" && (
								<PointerNavItem>
									<NavLink to="/members" onClick={toggle}>
										<Icon name="user" />
										Members
									</NavLink>
								</PointerNavItem>
							)}
					</MenuSection>
				</NavItemContainer>
			) : (
				<NavItemContainer>
					<NavItem>
						<Dropdown item text={userData && userData.email}>
							<Dropdown.Menu>
								<Dropdown.Item
									text="Logout"
									onClick={signOut}
								/>
							</Dropdown.Menu>
						</Dropdown>
					</NavItem>
				</NavItemContainer>
			)}
		</NavWrapper>
	);
};
export default withRouter(Navigation);
