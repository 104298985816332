import styled from "styled-components";
import { Button } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

export const Block = styled.div`
	background-color: #fff;
	border-radius: 10px;
	padding: 2em;
	margin-bottom: 2em;
`;
export const RoundButton = styled(Button)`
	border-radius: 50% !important;
	background-color: ${props => {
		switch (props.color) {
			case "orange":
				return "#EB5B28";
			case "purple":
				return "#8E2E88";
			case "green":
				return "#8CBF43";
			case "teal":
				return "#43BFA1";
			case "grey":
				return "#6D6D6D";
			case "blue":
				return "#2570C0";
			default:
				return "#a9a9a9";
		}
	}} !important;
`;

export const HoverButton = styled(Button)`
	position: fixed;
	bottom: 10px;
	right: 10px;
`;

export const TableContainer = styled.div`
	padding: 1rem;

	table {
		width: 100%;
		border-spacing: 0;
		th,
		td {
			border-bottom: 1px solid rgba(74, 74, 74, 0.1);
			text-align: left;
			margin: 0;
			padding: 0.5rem;
		}
		.ui.input {
			margin: 0.5em 0;
		}
	}

	.centered {
	}
	.pagination {
		margin: 2em auto 0 auto;
		display: grid;
		grid-template-columns: 40px 40px 1fr 40px 40px;
		justify-items: center;
		grid-column-gap: 0.5em;
		.controls {
			margin: 0 1em;
			span {
				margin: 0 0.5em;
			}
		}
	}
`;

export const EmptyTableState = styled.td`
	text-align: center !important;
	color: #c4c4c4;
	font-weight: 700;
	padding: 2em !important;
	h3.ui.header {
		color: #c4c4c4;
		margin: 0.5em;
	}
	p {
		margin-top: 0.5em;
	}
`;

export const EmptyState = styled.div`
	text-align: center !important;
	color: #c4c4c4;
	font-weight: 700;
	h3.ui.header {
		color: #c4c4c4;
		margin: 0.5em;
	}
	p {
		margin-top: 0.5em;
	}
`;

export const DocumentTableContainer = styled(TableContainer)`
	table {
		td,
		th {
			/* changing width of last column for actions */
			:last-child {
				width: 145px;
			}

			:nth-child(3) {
				width: 85px;
			}
			:nth-child(4) {
				width: 120px;
			}

			/* changing alignment of first column for icon */

			:first-child {
				width: 50px;
				text-align: right;
				i {
					margin: 0;
				}
			}
		}
	}
`;
export const UsersTableContainer = styled(TableContainer)`
	table {
		td,
		th {
			/* changing width of last column for actions */
			:last-child {
				width: 145px;
			}
			:nth-child(4) {
				width: 140px !important;
			}
		}
	}
`;

export const CentresTableContainer = styled(TableContainer)`
	.pagination {
		margin: 2em auto 0 auto;
		display: grid;
		grid-template-columns: 40px 40px 1fr 40px 40px;
		justify-items: center;
		grid-column-gap: 0.5em;
		align-items: center;
		button {
			height: 40px;
		}
		.controls {
			display: grid;
			justify-items: center;
			margin: 0 1em;
			.divider {
				display: none;
			}
			span {
				margin: 0 0.5em;
			}
		}
	}
`;

export const AdminViewTableContainer = styled(TableContainer)`
	height: 350px;
	table {
		td,
		th {
			/* changing width of last column for actions */
			:last-child {
				width: 145px;
			}
			:nth-child(4) {
				width: 140px !important;
			}
		}
	}
`;

export const InputPlaceholder = styled.div`
	line-height: 1.214286em;
	color: transparent;
	padding: 0.67857143em 0;
	margin: 0.5em 0;
`;

export const SeeAllLink = styled(NavLink)`
	width: 100%;
	display: block;
	color: #eb5b28;
	text-align: center;
	:hover {
		color: #e84910;
	}
`;

export const DocFilterBlock = styled.div`
	padding: ${props => {
		return props.small ? "0.7em" : "1.5em";
	}};
	cursor: pointer;
	text-align: center;
	border-radius: 15px;
	border: 3px solid;
	font-weight: ${props => {
		return props.small ? 500 : 700;
	}};
	font-size: 1.2em;
	background-color: ${props => {
		if (props.active) {
			switch (props.color) {
				case "orange":
					return "#EB5B28";
				case "purple":
					return "#8E2E88";
				case "green":
					return "#8CBF43";
				case "teal":
					return "#43BFA1";
				case "blue":
					return "#2570C0";

				default:
					return "#AEAEAE";
			}
		} else {
			return "transparent";
		}
	}};

	border-color: ${props => {
		switch (props.color) {
			case "orange":
				return "#EB5B28";
			case "purple":
				return "#8E2E88";
			case "green":
				return "#8CBF43";
			case "teal":
				return "#43BFA1";
			case "blue":
				return "#2570C0";

			default:
				return "#AEAEAE";
		}
	}};
	color: ${props => {
		if (!props.active) {
			switch (props.color) {
				case "orange":
					return "#EB5B28";
				case "purple":
					return "#8E2E88";
				case "green":
					return "#8CBF43";
				case "teal":
					return "#43BFA1";
				case "blue":
					return "#2570C0";

				default:
					return "#AEAEAE";
			}
		} else {
			return "white";
		}
	}};

	@media (max-width: 800px) {
		padding: 0.6em;
		min-height: 60px;
		max-width: 90px;
		min-width: 73px;
		font-size: 14px;
		display: grid;
		align-items: center;
	}
`;
