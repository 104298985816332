import React from "react";
import { Block, RoundButton } from "../../app/common/styledComponents/Elements";
import { Icon, Header, Popup } from "semantic-ui-react";
import ClassLabel from "../../app/common/components/ClassLabel";
import TermLabel from "../../app/common/components/TermLabel";

import styled from "styled-components";
import { formatBytes } from "../../app/common/utils";

const CardGrid = styled.div`
	display: grid;
	height: 100%;
	grid-template-rows: 3em auto 90px;
`;

const CardFoot = styled.div`
	display: grid;
	grid-row-gap: 0.5em;
	.info {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 0.5em;
		align-items: center;
		p {
			text-align: center;
			margin: 0;
		}
	}
	.actions {
		display: grid;
		grid-template-columns: repeat(3, 40px);
		grid-column-gap: 0.5em;
		align-items: center;
	}
`;

const CardBlock = styled(Block)`
	border: 1px solid #dedede;
	margin-bottom: 0;
`;

export default function DocumentCard({ document, firebase, favourite }) {
	return document.acf.document ? (
		<CardBlock>
			<CardGrid>
				<Header as="h3">
					<span
						dangerouslySetInnerHTML={{
							__html: document.title.rendered
						}}
					/>
				</Header>
				<Header as="h4" dividing>
					<span
						dangerouslySetInnerHTML={{
							__html: document.content.rendered
						}}
					/>
				</Header>
				<CardFoot>
					<div className="info">
						<ClassLabel classSlug={document.acf.ages && document.acf.ages.slug} />
						<TermLabel termSlug={document.acf.term && document.acf.term.slug} />
						<p>{formatBytes(document.acf.document.filesize, 1)}</p>
					</div>
					<div className="actions">
						<Popup
							size="mini"
							inverted
							content="View"
							trigger={
								<a
									href={document.acf.document.url}
									target="_blank"
									rel="noopener noreferrer"
								>
									<RoundButton icon color="grey">
										<Icon name="eye" />
									</RoundButton>
								</a>
							}
						/>
						<Popup
							size="mini"
							inverted
							content="Download"
							trigger={
								<a
									href={document.acf.document.url}
									download={document.acf.document.filename}
								>
									<RoundButton icon color="grey">
										<Icon name="arrow down" />
									</RoundButton>
								</a>
							}
						/>

						<span>
							{favourite ? (
								<Popup
									size="mini"
									inverted
									content="Unfavourite"
									trigger={
										<span>
											<RoundButton
												icon
												color="grey"
												onClick={() =>
													firebase.removeFavourite(
														document.id
													)
												}
											>
												<Icon name="heart" />
											</RoundButton>
										</span>
									}
								/>
							) : (
								<Popup
									size="mini"
									inverted
									content="Favourite"
									trigger={
										<span>
											<RoundButton
												icon
												color="grey"
												onClick={() =>
													firebase.addFavourite(
														document.id
													)
												}
											>
												<Icon name="heart outline" />
											</RoundButton>
										</span>
									}
								/>
							)}
						</span>
					</div>
				</CardFoot>
			</CardGrid>
		</CardBlock>
	) : null;
}
