import React from "react";

const RadioInput = ({ input, meta, values, name, label }) => {
	return (
		<div className={meta.error && meta.touched ? "error field" : "field"}>
			<div className="ui radio checkbox">
				{values[name] && values[name] === input.value ? (
					<input
						{...input}
						type="radio"
						value={input.value}
						checked="checked"
					/>
				) : (
					<input {...input} type="radio" value={input.value} />
				)}
				<label>{label}</label>
			</div>
		</div>
	);
};

export default RadioInput;
