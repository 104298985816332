import React, { useState } from "react";
import { Block } from "../../app/common/styledComponents/Elements";
import { Header, Form, Button, Message } from "semantic-ui-react";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";
import { useUserContext } from "../../app/App";

const FormGrid = styled.div`
	display: grid;
	button {
		justify-self: right;
	}
`;

export default function Password({ firebase }) {
	const { addToast } = useToasts();
	const [formValues, setFormValues] = useState({});
	const [error, setError] = useState();
	const { userData } = useUserContext();

	const handleChange = (evt, { value }) => {
		setFormValues({
			...formValues,
			[evt.target.name]: value
		});
	};
	const onSubmit = async () => {
		await firebase
			.doSignInWithEmailAndPassword(
				userData.email,
				formValues.oldPassword
			)
			.then(data =>
				firebase.doPasswordUpdate(formValues.password1).then(data => {
					setFormValues({
						oldPassword: "",
						password1: "",
						password2: ""
					});
					addToast("Password successfully updated", {
						appearance: "success",
						autoDismiss: true,
						autoDismissTimeout: 2000
					});
				})
			)
			.catch(err => {
				console.log(err);
				setError(err.message);
			});
	};

	const bothEntered = formValues.password1 && formValues.password2;
	const isMatch = formValues.password1 === formValues.password2;
	const isLong = formValues.password1 && formValues.password1.length >= 6;
	const isValid = bothEntered && isMatch && isLong;

	const oldValid = formValues.oldPassword && formValues.oldPassword >= 6;
	return (
		<Block>
			<Header
				as="h3"
				style={{
					textTransform: "uppercase"
				}}
			>
				Your Password
			</Header>
			<Header
				as="h4"
				dividing
				style={{
					textTransform: "uppercase"
				}}
			>
				Change password
			</Header>
			<Form onSubmit={onSubmit}>
				<FormGrid>
					<Form.Group widths="equal">
						<Form.Input
							label="Old Password"
							type="password"
							name="oldPassword"
							onChange={handleChange}
							value={formValues.oldPassword}
							fluid
							error={error}
						/>
						<Form.Input
							label="New Password"
							type="password"
							name="password1"
							onChange={handleChange}
							value={formValues.password1}
							fluid
						/>
						<Form.Input
							label="Confirm Password"
							type="password"
							name="password2"
							onChange={handleChange}
							value={formValues.password2}
							fluid
						/>
					</Form.Group>
					{bothEntered && !isMatch && (
						<Message color="orange">
							<Message.Header>
								Passwords do not match
							</Message.Header>
							<p>Please check that both the passwords match</p>
						</Message>
					)}
					{formValues.password1 && !isLong && (
						<Message color="orange">
							<Message.Header>
								Password is not long enough
							</Message.Header>
							<p>
								Your password must be at least 6 characters long
							</p>
						</Message>
					)}

					<Button
						color="orange"
						type="submit"
						disabled={!(isValid && oldValid)}
					>
						Change Password
					</Button>
				</FormGrid>
			</Form>
		</Block>
	);
}
