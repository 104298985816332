import React, { useState, useEffect } from "react";
import { Block } from "../../app/common/styledComponents/Elements";
import { Image, Container, Header, Icon, Divider } from "semantic-ui-react";
import { NavLink, withRouter } from "react-router-dom";
import {
  AppContainer,
  HeaderContainer
} from "../../app/common/styledComponents/Layout";
import styled from "styled-components";
import Axios from "axios";
import { formatDistanceToNow } from "date-fns";

const NewsSingle = ownProps => {
  const [article, setArticle] = useState([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [wpDate, setWpDate] = useState(null);
  const [categoryIds, setCategoryIds] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  // Get ID from URL
  const articleId = ownProps.match.params.id;

  // Get the article
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_WP_API}/posts/${articleId}`).then(
      res => {
        setArticle(res.data);
        setTitle(res.data.title.rendered);
        setContent(res.data.content.rendered);
        setCategoryIds(res.data.categories);
      }
    );
  }, [articleId]);

  // Get Names of Categories from IDs
  useEffect(() => {
    const cats = categoryIds.map(cat =>
      Axios.get(`${process.env.REACT_APP_WP_API}/categories/${cat}`)
    );

    Promise.all(cats).then(res => {
      const categories = res.map(r => {
        return r.data.name;
      });
      setAllCategories(categories);
    });
  }, [categoryIds]);

  // Set the article Date format & get pictureLink
  useEffect(() => {
    if (article.date && article.featured_media) {
      setWpDate(new Date(article.date));
      Axios.get(
        `${process.env.REACT_APP_WP_API}/media/${article.featured_media}`
      ).then(res => {
        setImageLink(res.data.guid.rendered);
      });
    }
  }, [article]);

  // Map Categories
  const Categories = ({ cats }) => {
    return cats.map(cat => {
      return (
        <CategoryItem key={cat} className='floatingCategory'>
          {cat}
        </CategoryItem>
      );
    });
  };

  return (
    <AppContainer>
      <Container>
        <HeaderContainer>
          <Header as='h2' color='orange' style={{ textTransform: "uppercase" }}>
            <Icon name='newspaper' size='mini' />
            <Header.Content>News Article</Header.Content>
          </Header>
        </HeaderContainer>
        <Block>
          <ArticleHeader>
            <div className='contentBock'>
              <h1>{title}</h1>
              <span>
                Posted {wpDate !== null && formatDistanceToNow(wpDate)} ago
              </span>
              <CategorySection>
                Categories: <Categories cats={allCategories} />
              </CategorySection>
            </div>
          </ArticleHeader>
          <Divider />
          <ArticleBody>
            <div className='imageBock'>
              {imageLink && <Image src={imageLink} alt='post' />}
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: content
              }}
            />
          </ArticleBody>
          <Divider />
          <ArticleFooter>
            <BackButton to='/news'>Back to News</BackButton>
          </ArticleFooter>
        </Block>
      </Container>
    </AppContainer>
  );
};

export default withRouter(NewsSingle);

const CategoryItem = styled.span`
  color: #fff;
  background-color: #eb5b28;
  padding: 5px 10px;
  font-weight: 600;
  margin-right: 5px;
  border-radius: 30px;
`;

const CategorySection = styled.div`
  margin-top: 20px;
`;

const ArticleHeader = styled.div`
  max-width: 700px;
  margin: 30px auto;
  h1 {
    font-size: 38px;
  }
`;

const ArticleBody = styled.div`
  max-width: 700px;
  margin: 30px auto;

  p {
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 1.4;
    line-height: 1.7;
  }
  .wp-block-image {
    margin: 20px 0px !important;
  }
  img {
    max-width: 100%;
  }
  .imageBock {
    margin-bottom: 40px;
    img {
      width: 100%;
    }
  }
`;

const ArticleFooter = styled.div`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
`;

const BackButton = styled(NavLink)`
  background-color: transparent;
  border: 2px solid #767676;
  color: #767676;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  margin-right: 0;
  margin-left: 0.25em;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  :hover {
    border-color: #f26201;
    background-color: #f26201;
    color: #fff;
  }
`;
