import React from "react";
import { Container, Header, Icon, Tab } from "semantic-ui-react";
import {
	AppContainer,
	HeaderContainer
} from "../../app/common/styledComponents/Layout";
import { Block } from "../../app/common/styledComponents/Elements";
import TermDates from "./Terms/TermDates";
import ManageCentres from "./Centres/ManageCentres";

export default function Settings({ firebase }) {
	const panes = [
		{
			menuItem: "Set Term Dates",
			render: () => <TermDates firebase={firebase} />
		},
		{
			menuItem: "Manage Centres",
			render: () => <ManageCentres firebase={firebase} />
		}
	];
	return (
		<AppContainer>
			<Container>
				<HeaderContainer>
					<Header
						as="h2"
						color="orange"
						style={{ textTransform: "uppercase" }}
					>
						<Icon name="cog" size="mini" />
						<Header.Content>Portal Settings</Header.Content>
					</Header>
				</HeaderContainer>
				<Block>
					<Tab
						menu={{ secondary: true, pointing: true }}
						panes={panes}
					/>
				</Block>
			</Container>
		</AppContainer>
	);
}
