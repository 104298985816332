import React from "react";
import { Header, Image, Button } from "semantic-ui-react";
import { FullheightSplitGrid } from "../../../app/common/styledComponents/Layout";
import styled from "styled-components";

const HeroImage = styled.div`
	min-height: 100vh;
	height: 100%;
	background-image: url("/2.jpg");
	background-position: center;
	background-size: cover;
	display: grid;
	align-items: end;
	justify-items: end;
	img {
		margin: 2em 5%;
	}
	h2 {
		padding-bottom: 1em !important;
	}
`;

const ThankYou = ({ firebase, user, isLoading }) => {
	const signOut = () => {
		firebase.doSignOut();
		window.location.replace("/");
	};

	if (isLoading) {
		return <p>Loading...</p>;
	}
	return (
		<FullheightSplitGrid style={{ alignItems: "center" }}>
			<div className="content">
				<Header
					as="h1"
					textAlign="center"
					className="logo"
					color="orange"
				>
					<Image src="/logo.svg" /> Welcome to UpliftED
				</Header>
				<Header as="h2" textAlign="center" color="grey">
					Thanks for joining {user ? user.displayName : ""}
				</Header>
				<Button
					onClick={signOut}
					color="orange"
					fluid
					style={{ margin: "2em 0" }}
				>
					New Registration
				</Button>
			</div>
			<div>
				<HeroImage>
					<Image src="/bot-logo-white.svg" size="small" />
				</HeroImage>
			</div>
		</FullheightSplitGrid>
	);
};

export default ThankYou;
