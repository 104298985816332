import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";
import { Header, Image, Dropdown, Container } from "semantic-ui-react";
import { useUserContext } from "../../App";
import NavIcon from "./navicon";
import NavigationDrawer from "./navigationDrawer";

const Nav = styled.nav`
	background: #eb5b28;

	@media (max-width: 1200px) {
		> div > div {
			grid-template-columns: 1fr;

			> div {
				justify-self: center;
				margin-right: 0;
			}
			.logo {
				margin: 0.5em !important;
			}
		}
	}

	@media (max-width: 800px) {
		> div > div {
			> div {
				justify-self: start;
			}
		}
	}
`;

const NavContainer = styled.div`
	background: #eb5b28;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 3fr;
	align-items: center;
	@media (max-width: 800px) {
		display: none;
	}
`;

const NavItemContainer = styled.div`
	display: grid;
	grid-template-columns: ${props => {
		switch (props.items) {
			case 5:
				return "auto auto auto auto auto";
			case 6:
				return "auto auto auto auto auto auto";
			default:
				return "auto auto auto auto auto";
		}
	}};
	justify-self: end;
`;

const NavItem = styled.div`
	display: inline-flex;
	font-weight: 600 !important;
	color: #fff !important;
	padding: 0;
	position: relative;
	vertical-align: middle;
	text-decoration: none;
	-webkit-tap-highlight-color: transparent;
	flex: 0 0 auto;
	margin-left: ${props => props.right};
	a {
		color: #fff;
		padding: 1.9em 1.3em;
		transition: 0.2s all ease-in-out;
		:hover {
			background: #e84910;
		}
	}
	> a[aria-current="page"] {
		background: #e84910 !important;
	}
	> div {
		color: #fff;
		padding: 1.9em 1.3em;
		transition: 0.2s all ease-in-out;
		:hover {
			background: #e84910;
		}
		z-index: 2147483649;
	}

	.logo {
		color: #fff;
		margin: 0 !important;
		display: grid;
		grid-template-columns: 1fr 3fr;
		align-items: center;
		img {
			width: auto;
		}
	}

	.ui.dropdown .menu {
		width: 100%;
		border-radius: 0 0 10px 10px;
		background: #e84910;
		border-width: 0;
		.divider {
			border-top: 1px solid rgba(255, 255, 255, 0.5);
		}
		a.item,
		div.item {
			color: white;
			padding: 1.5em 1em !important;
			font-weight: 600;

			transition: 0.2s all ease-in-out;
			:hover {
				color: white;
				background: transparent;
				font-weight: 900;
			}
		}
	}
`;

const PointerNavItem = styled(NavItem)`
	text-transform: uppercase;
	a[aria-current="page"] {
		background: #e84910 !important;
		::after {
			background: #e84910 !important;
			box-shadow: none !important;
			border: none !important;
			visibility: visible;
			width: 1em;
			height: 1em;
			position: absolute;
			content: "";
			top: 100%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%) rotate(45deg);
			margin: 0 !important;
			border: none;
			border-bottom: 1px solid #d4d4d5;
			border-right: 1px solid #d4d4d5;
			z-index: 2;
			transition: background 0.1s ease;
		}
	}
`;

const NavLogo = styled(NavItem)`
	margin-right: 10%;
`;

const MobileNavContainer = styled.div`
	display: none;
	@media (max-width: 800px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
`;

const Navbar = ({ firebase }) => {
	const [showMobileMenu, setShowMobileMenu] = useState(false);

	const mobileMenuToggleHandler = () => {
		setShowMobileMenu(!showMobileMenu);
	};

	const signOut = () => {
		firebase.doSignOut();
	};
	const { userData } = useUserContext();

	return (
		<>
			<Nav>
				<Container>
					<NavContainer>
						<NavLogo>
							<Header as="h1" className="logo">
								<Image src="/logo-white.svg" size="big" />
								UpliftED
							</Header>
						</NavLogo>
						{userData &&
						userData.isApproved &&
						!userData.isArchived ? (
							<NavItemContainer
								items={
									userData &&
									userData.role &&
									userData.role === "admin"
										? 6
										: 5
								}
							>
								<PointerNavItem>
									<NavLink to="/dashboard">Dashboard</NavLink>
								</PointerNavItem>
								<PointerNavItem>
									<NavLink to="/documents">Documents</NavLink>
								</PointerNavItem>
								<PointerNavItem>
									<NavLink to="/news">News</NavLink>
								</PointerNavItem>
								<PointerNavItem>
									<NavLink to="/discussions">
										Discussions
									</NavLink>
								</PointerNavItem>

								{userData &&
									userData.role &&
									userData.role === "admin" && (
										<PointerNavItem>
											<NavLink to="/members">
												Members
											</NavLink>
										</PointerNavItem>
									)}
								<NavItem>
									<Dropdown
										item
										text={userData && userData.email}
									>
										<Dropdown.Menu>
											{userData &&
												userData.role &&
												userData.role === "admin" && (
													<Dropdown.Item
														as={NavLink}
														to={`/settings`}
														text="Portal Settings"
													/>
												)}
											<Dropdown.Item
												as={NavLink}
												to={`/profile-settings`}
												text="Profile Settings"
											/>
											<Dropdown.Divider />
											<Dropdown.Item
												text="Logout"
												onClick={signOut}
											/>
										</Dropdown.Menu>
									</Dropdown>
								</NavItem>
							</NavItemContainer>
						) : (
							<NavItemContainer>
								<NavItem>
									<Dropdown
										item
										text={userData && userData.email}
									>
										<Dropdown.Menu>
											<Dropdown.Item
												text="Logout"
												onClick={signOut}
											/>
										</Dropdown.Menu>
									</Dropdown>
								</NavItem>
							</NavItemContainer>
						)}
					</NavContainer>
					<MobileNavContainer>
						<NavLogo>
							<Header as="h1" className="logo">
								<Image src="/logo-white.svg" size="big" />
								UpliftED
							</Header>
						</NavLogo>
						<div
							className="mobile-nav-trigger"
							onClick={mobileMenuToggleHandler}
						>
							<NavIcon open={showMobileMenu} />
						</div>
					</MobileNavContainer>
				</Container>
			</Nav>
			<div className="nav-holder">
				<NavigationDrawer
					isOpen={showMobileMenu}
					toggle={mobileMenuToggleHandler}
				/>
			</div>
		</>
	);
};

export default withRouter(Navbar);
