import React, { Component } from "react";
import { Field } from "react-final-form";
import TextInput from "../../../app/common/form/AuthTextInput";
import { Header } from "semantic-ui-react";
import styled from "styled-components";

const PasswordMessage = styled.p`
	margin-top: -10px !important;
	font-size: 0.9em;
`;

export default class RegisterStep1 extends Component {
	render() {
		const { required, mustBeLongerThan6, composeValidators } = this.props;
		return (
			<div className="ui form">
				<Header as="h2" color="grey" textAlign="center">
					Register a new account
				</Header>
				<Field
					name="firstName"
					validate={required}
					fluid
					placeholder="Jane"
					label="First name"
					component={TextInput}
				/>
				<Field
					name="lastName"
					validate={required}
					fluid
					placeholder="Doe"
					label="Last Name"
					component={TextInput}
				/>

				<Field
					name="email"
					validate={required}
					fluid
					placeholder="example@mail.com"
					label="E-mail address"
					component={TextInput}
				/>
				<Field
					name="phone"
					validate={required}
					fluid
					placeholder="000 000 0000"
					label="Contact number"
					component={TextInput}
				/>

				<Field
					name="password"
					validate={composeValidators(required, mustBeLongerThan6)}
					fluid
					label="Password"
					placeholder="••••••"
					type="password"
					component={TextInput}
				/>
				<PasswordMessage>
					Password must be at least 6 characters long
				</PasswordMessage>
			</div>
		);
	}
}
