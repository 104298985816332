import React, { useState, useEffect } from "react";
import { useUserContext, useAuthContext } from "../../app/App";
import styled from "styled-components";
import { useToasts } from "react-toast-notifications";

import { Header, Button, Form } from "semantic-ui-react";
import { Block } from "../../app/common/styledComponents/Elements";

const FormGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 1em;
	margin-top: 2em;
	.interests {
		display: grid;
		height: auto;
		align-items: top;
	}
	button.ui.button {
		justify-self: right;
		margin-top: 1em;
	}
`;

export default function Details({ firebase }) {
	const { addToast } = useToasts();
	const [formValues, setFormValues] = useState({});
	const { userData } = useUserContext();
	const { user } = useAuthContext();

	const threeSelected = () => {
		return formValues.interests ? formValues.interests.length === 3 : false;
	};

	const interests = [
		"Early Childhood Education Research",
		"Working with Parents",
		"Creative / Art and Craft Ideas",
		"Science for ECD Learners",
		"Numeracy for ECD Learners",
		"Active outdoor Games for ECD learners",
		"Indoor Games for ECD learners",
		"Language activities for ECD leaners – chances to talk and express themselves",
		"Positive Behaviour management",
		"Supporting children with learning difficulties",
		"Team development",
		"Using books in early literacy"
	];

	const areasOfInterest = () => {
		return interests.map((i, idx) => {
			return (
				<Form.Checkbox
					disabled={
						formValues.interests &&
						!formValues.interests.includes(i) &&
						threeSelected()
					}
					name="interests"
					label={i}
					value={i}
					key={idx}
					checked={
						formValues.interests && formValues.interests.includes(i)
					}
					onChange={handleInterestsChange}
				/>
			);
		});
	};
	const classList = ["Babies", "Younger", "Older"];

	const classes = () => {
		return classList.map((c, idx) => {
			return (
				<Form.Checkbox
					name="classes"
					label={c}
					value={c.toLowerCase()}
					key={idx}
					checked={
						formValues.classes &&
						formValues.classes.includes(c.toLowerCase())
					}
					onChange={handleClassesChange}
				/>
			);
		});
	};

	const onSubmit = async () => {
		await firebase
			.user(user.uid)
			.update({
				...formValues,
				displayName: `${formValues.firstName} ${formValues.lastName}`
			})
			.then(data =>
				addToast("Saved Successfully", {
					appearance: "success",
					autoDismiss: true,
					autoDismissTimeout: 2000
				})
			);
	};
	const handleRadio = (e, { value }) =>
		setFormValues({
			...formValues,
			role: value
		});

	const handleClassesChange = (e, { value }) => {
		const classes = formValues.classes ? formValues.classes : [];
		let newClasses = [];
		if (classes.includes(value)) {
			newClasses = classes.filter(v => v !== value);
		} else {
			newClasses = [...classes, value];
		}
		setFormValues({ ...formValues, classes: newClasses });
	};

	const handleInterestsChange = (e, { value }) => {
		const interests = formValues.interests ? formValues.interests : [];
		let newInterests = [];
		if (interests.includes(value) && threeSelected) {
			newInterests = interests.filter(v => v !== value);
		} else {
			newInterests = [...interests, value];
		}
		setFormValues({
			...formValues,
			interests: newInterests
		});
	};

	const handleChange = (evt, { value }) => {
		setFormValues({
			...formValues,
			[evt.target.name]: value
		});
	};

	useEffect(() => {
		return setFormValues(userData);
	}, [userData]);

	return (
		<Block>
			<Header
				as="h3"
				style={{
					textTransform: "uppercase"
				}}
			>
				Your Details
			</Header>
			<Header
				as="h4"
				dividing
				style={{
					textTransform: "uppercase"
				}}
			>
				Personal Details
			</Header>

			<Form onSubmit={onSubmit}>
				<Form.Group widths="equal">
					<Form.Input
						readOnly
						label="First Name"
						type="text"
						name="firstName"
						onChange={handleChange}
						value={formValues.firstName}
						fluid
					/>
					<Form.Input
						readOnly
						label="Last Name"
						type="text"
						name="lastName"
						onChange={handleChange}
						value={formValues.lastName}
						fluid
					/>
				</Form.Group>
				<Form.Input
					readOnly
					label="Email Address"
					type="text"
					name="email"
					onChange={handleChange}
					value={formValues.email}
					fluid
				/>
				<Form.Input
					readOnly
					label="Phone Number"
					type="text"
					name="phone"
					onChange={handleChange}
					value={formValues.phone}
					fluid
				/>
			</Form>
			<FormGrid>
				<span>
					<Header
						as="h4"
						dividing
						style={{
							textTransform: "uppercase"
						}}
					>
						Centre Details
					</Header>
					<Form>
						<Form.Input
							label="EDC Centre"
							type="text"
							name="centre"
							onChange={handleChange}
							value={formValues.centre}
							fluid
						/>

						<Form.Group inline>
							<label>Role</label>
						</Form.Group>
						<Form.Group inline>
							<Form.Radio
								readOnly
								disabled={formValues.role !== "practitioner"}
								name="role"
								label="Practitioner"
								value="practitioner"
								checked={formValues.role === "practitioner"}
								onChange={handleRadio}
							/>
							<Form.Radio
								readOnly
								name="role"
								label="Principal"
								value="principal"
								checked={formValues.role === "principal"}
								disabled={formValues.role !== "principal"}
								onChange={handleRadio}
							/>
							<Form.Radio
								readOnly
								name="role"
								label="Admin"
								value="admin"
								checked={formValues.role === "admin"}
								disabled={formValues.role !== "admin"}
								onChange={handleRadio}
							/>
						</Form.Group>
						<Form.Group inline>
							<label>Classes you teach</label>
						</Form.Group>

						{classes()}
					</Form>
				</span>
				<span className="interests">
					<Header
						as="h4"
						dividing
						style={{
							textTransform: "uppercase"
						}}
					>
						Interests
					</Header>
					<Form>{areasOfInterest()}</Form>
					<Button color="orange" onClick={onSubmit}>
						Update
					</Button>
				</span>
			</FormGrid>
		</Block>
	);
}
