import React, { useState } from "react";
import { Header, Image, Button } from "semantic-ui-react";
import { FullheightSplitGridForm } from "../../../app/common/styledComponents/Layout";
//import { Field } from "react-final-form";
import styled from "styled-components";
import Wizard from "../../../app/common/form/Wizard";
import RegisterStep1 from "./RegisterStep1";
import RegisterStep2 from "./RegisterStep2";
import RegisterStep3 from "./RegisterStep3";
import release from "../../../app/config/release.json";
import { NavLink } from "react-router-dom";

const HeroImage = styled.div`
	min-height: 100vh;
	height: 100%;
	background-image: url("/8.jpg");
	background-position: center;
	background-size: cover;
	display: grid;
	align-items: end;
	justify-items: end;
	img {
		margin: 2em 5%;
	}
`;

const LoginBlock = styled.div`
	text-align: center;
`;

/* const FormBlock = styled.div`
	padding: 2em;
	width: 70%;
	margin: auto;
	.logo {
		padding-top: 3em !important;
		padding-bottom: 1em !important;
	}
`; */

/* const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const Error = ({ name }) => (
	<Field
		name={name}
		subscribe={{ touched: true, error: true }}
		render={({ meta: { touched, error } }) =>
			touched && error ? <span>{error}</span> : null
		}
	/>
); */

const required = value => (value ? undefined : "Required");
/* const mustBeNumber = ({ value }) => {
	return isNaN(parseInt(value))
		? "Phone number must only be numbers"
		: undefined;
}; */

const mustBeLongerThan6 = ({ value }) => {
	return value.length < 6
		? "Password must be at least 6 characters"
		: undefined;
};
const composeValidators = (...validators) => value =>
	validators.reduce(
		(error, validator) => error || validator(value),
		undefined
	);

const Register = ({ firebase }) => {
	const [values, setValues] = useState();
	const [error, setError] = useState();
	const exposedValues = ({ values, valid }) => {
		setValues(values);
	};
	const onSubmit = async values => {
		const valuesForFormatting = {};
		Object.keys(values)
			.filter(key => values[key].value)
			.forEach(key => {
				valuesForFormatting[key] = values[key];
			});
		const formattedValues = {};
		Object.keys(values)
			.filter(key => !values[key].value)
			.forEach(key => {
				formattedValues[key] = values[key];
			});

		Object.keys(valuesForFormatting).forEach(key => {
			formattedValues[key] = values[key].value;
		});

		formattedValues.displayName = `${formattedValues.firstName} ${formattedValues.lastName}`;
		firebase
			.doCreateUserWithEmailAndPassword(
				formattedValues.email,
				formattedValues.password
			)
			.then(authUser => {
				delete formattedValues.password;
				return firebase
					.user(authUser.user.uid)
					.set(
						{
							...formattedValues,
							isApproved: false,
							isArchived: false,
							role: formattedValues.role
								? formattedValues.role
								: "practitioner"
						},
						{ merge: true }
					)
					.then(() => {
						firebase.doUpdateDisplayName(
							formattedValues.displayName
						);
					})
					.then(() => {
						window.location.replace("/dashboard");
					});
			})
			.catch(error => {
				console.log(error);
				setError(error);
			});
	};
	return (
		<FullheightSplitGridForm>
			<div className="content">
				<div className="logo-header">
					<Header
						as="h1"
						color="orange"
						textAlign="center"
						className="logo"
					>
						<Image src="/logo.svg" />
						UpliftED
					</Header>
					<Header as="h4" color="grey" textAlign="center">
						Teacher Training Portal
					</Header>
				</div>
				<div>
					<Wizard
						onSubmit={onSubmit}
						exposeValues={exposedValues}
						error={error}
					>
						<Wizard.Page>
							<RegisterStep1
								required={required}
								mustBeLongerThan6={mustBeLongerThan6}
								composeValidators={composeValidators}
								values={values}
							/>
						</Wizard.Page>
						<Wizard.Page>
							<RegisterStep2
								required={required}
								values={values}
							/>
						</Wizard.Page>
						<Wizard.Page>
							<RegisterStep3
								required={required}
								values={values}
							/>
						</Wizard.Page>
					</Wizard>
				</div>
				<LoginBlock>
					<p>Already have an account? </p>
					<NavLink to="/">
						<Button color="orange" size="large">
							Login
						</Button>
					</NavLink>
				</LoginBlock>
				<div className="version">Version: {release.versionClient}</div>
			</div>
			<div>
				<HeroImage>
					<Image src="/bot-logo-white.svg" size="small" />
				</HeroImage>
			</div>
		</FullheightSplitGridForm>
	);
};

export default Register;
