import React, { useEffect, useState, useMemo } from 'react';
import Table from '../../../app/common/components/Table';
import Axios from 'axios';
import { formatBytes } from '../../../app/common/utils';
import { Icon, Popup, Message, Header } from 'semantic-ui-react';
import {
	RoundButton,
	DocumentTableContainer,
	EmptyState
} from '../../../app/common/styledComponents/Elements';
import ClassLabel from '../../../app/common/components/ClassLabel';
import { useUserContext, useAuthContext } from '../../../app/App';
import styled from 'styled-components';

const DesktopView = styled.div`
	@media (max-width: 800px) {
		display: none;
	}
`;

const MobileView = styled.div`
	display: none;
	@media (max-width: 800px) {
		display: block;
	}
`;

const DocumentTable = ({ firebase, classValue, showFavouriteValue }) => {
	const [documents, setDocuments] = useState([]);
	const { terms } = useAuthContext();
	const { userData } = useUserContext();
	const [isLoading, setLoading] = useState(false);

	const [docConfig, setDocConfig] = useState({
		posts: [],
		baseUrl: `${process.env.REACT_APP_WP_API}/documents`,
		perPage: '?per_page=100',
		termIds: [6, 7, 8, 9]
	});
	console.log(documents);
	useEffect(() => {
		const CancelToken = Axios.CancelToken;
		const source = CancelToken.source();

		async function getNumPages(term) {
			const { headers } = await Axios(
				`${docConfig.baseUrl}${docConfig.perPage}&school_term=${term}`
			);
			return headers['x-wp-totalpages'];
		}
		async function fetchDocuments(numPages, term) {
			const posts = [];
			for (let page = 1; page <= numPages; page += 1) {
				const post = Axios.get(
					`${docConfig.baseUrl}${docConfig.perPage}&school_term=${term}&page=${page}`
				);
				posts.push(post);
			}
			await Axios.all(posts)
				.then(response => {
					const postData = response.map(res => res.data).flat();
					setDocuments(prev => [...prev, ...postData]);
				})
				.catch(e => console.log('error fetching posts: ', e));
			return true;
		}
		const fetchData = async () => {
			setLoading(true);

			let termCount = 0;
			let termCounter = 0;
			Object.values(terms).forEach(async (term, idx) => {
				if (term) {
					termCount++;
					getNumPages(docConfig.termIds[idx]).then(numPages =>
						fetchDocuments(numPages, docConfig.termIds[idx])
					);
					termCounter++;
				}
			});
			getNumPages(19).then(numPosts => fetchDocuments(numPosts, 19));

			if (termCounter === termCount) setLoading(false);
		};
		fetchData();
		return () => {
			source.cancel();
		};
	}, [docConfig, terms]);
	const data = useMemo(
		() =>
			documents
				.filter(d => d && d.acf && d.acf.document)

				.filter(d => {
					if (!showFavouriteValue) {
						return d;
					}
					return (
						userData.favouriteDocs && userData.favouriteDocs.includes(d.id)
					);
				}),
		[userData, showFavouriteValue, documents]
	);

	const columns = useMemo(
		() => [
			{
				Header: '',
				id: 'file',
				Cell: () => {
					return <Icon name="file outline" />;
				},
				minWidth: 10
			},
			{
				Header: 'Name',
				id: 'name',
				accessor: d => (d.title ? d.title.rendered : ''),
				Cell: cell => {
					return (
						<span
							dangerouslySetInnerHTML={{
								__html: cell.row.original.title.rendered
							}}
						/>
					);
				},
				filter: 'fuzzyText'
			},
			{
				Header: 'Size',
				id: 'size',
				accessor: d => formatBytes(d.acf.document.filesize, 1),
				disableFilters: true
			},
			{
				Header: 'Class',
				id: 'class',
				className: 'centered',
				accessor: d => d.acf.ages.slug,
				Cell: cell => (
					<ClassLabel classSlug={cell.row.original.acf.ages.slug} />
				),
				disableFilters: true
			},
			{
				Header: 'Actions',
				accessor: d => d.acf.document.url,
				Cell: cell => (
					<span>
						<Popup
							size="mini"
							inverted
							content="View"
							trigger={
								<a
									href={cell.row.original.acf.document.url}
									target="_blank"
									rel="noopener noreferrer"
								>
									<RoundButton icon color="grey">
										<Icon name="eye" />
									</RoundButton>
								</a>
							}
						/>
						<Popup
							size="mini"
							inverted
							content="Download"
							trigger={
								<a
									href={cell.row.original.acf.document.url}
									download={cell.row.original.acf.document.filename}
								>
									<RoundButton icon color="grey">
										<Icon name="arrow down" />
									</RoundButton>
								</a>
							}
						/>

						{userData &&
						userData.favouriteDocs &&
						userData.favouriteDocs.includes(cell.row.original.id) ? (
							<Popup
								size="mini"
								inverted
								content="Unfavourite"
								trigger={
									<span>
										<RoundButton
											icon
											color="grey"
											onClick={() =>
												firebase.removeFavourite(cell.row.original.id)
											}
										>
											<Icon name="heart" />
										</RoundButton>
									</span>
								}
							/>
						) : (
							<Popup
								size="mini"
								inverted
								content="Favourite"
								trigger={
									<span>
										<RoundButton
											icon
											color="grey"
											onClick={() =>
												firebase.addFavourite(cell.row.original.id)
											}
										>
											<Icon name="heart outline" />
										</RoundButton>
									</span>
								}
							/>
						)}
					</span>
				),
				className: 'actions',
				disableFilters: true
			}
		],
		[firebase, userData]
	);
	return (
		<>
			<DesktopView>
				<DocumentTableContainer>
					<Table
						columns={columns}
						data={data}
						loading={isLoading}
						defaultRows={5}
						emptyMsg="You haven't favourited any documents yet"
						emptyIcon="heart outline"
					/>
				</DocumentTableContainer>
			</DesktopView>
			<MobileView>
				{data.length > 0 ? (
					<div style={{ textAlign: 'center' }}>
						<Icon size="big" name="heart outline" />
						<Header as="h3">
							You have {data && data.length} favourited{' '}
							{data && data.length === 1 ? 'document' : 'documents'}
						</Header>
					</div>
				) : (
					<EmptyState style={{ gridColumn: '1 / 3 span' }}>
						<Icon size="big" name="heart outline" />
						<Header as="h3">You haven't favourited any documents yet</Header>
					</EmptyState>
				)}
			</MobileView>
		</>
	);
};

export default DocumentTable;
