import React from "react";
import { AppFooter } from "../styledComponents/Layout";
import { Image, Container } from "semantic-ui-react";
import release from "../../../app/config/release.json";

export default function Footer() {
	return (
		<AppFooter>
			<Container>
				<div className="grid">
					<div className="left-img">
						Sponsored by
						<Image
							src="/Anglo_American_Logo.svg"
							className="left-img"
						/>
						Ambassadors for Good
					</div>

					<div className="version">
						Version: {release.versionClient}
					</div>
					<Image src="/bot-logo-grey.svg" className="right-img" />
				</div>
			</Container>
		</AppFooter>
	);
}
