import React, { Component } from "react";
import { Form, Header } from "semantic-ui-react";
import { Field } from "react-final-form";

const interests = [
	"Early Childhood Education Research",
	"Working with Parents",
	"Creative / Art and Craft Ideas",
	"Science for ECD Learners",
	"Numeracy for ECD Learners",
	"Active outdoor Games for ECD learners",
	"Indoor Games for ECD learners",
	"Language activities for ECD leaners – chances to talk and express themselves",
	"Positive Behaviour management",
	"Supporting children with learning difficulties",
	"Team development",
	"Using books in early literacy"
];

export default class RegisterStep3 extends Component {
	threeSelected = () => {
		const { values } = this.props;
		return values.interests ? values.interests.length === 3 : false;
	};
	areasOfInterest = () => {
		return interests.map((i, idx) => {
			const { values } = this.props;
			return (
				<div className="field" key={idx}>
					<div className="ui checkbox">
						<Field
							disabled={
								values.interests &&
								!values.interests.includes(i) &&
								this.threeSelected()
							}
							name="interests"
							component="input"
							label={i}
							type="checkbox"
							value={i}
						/>
						<label>{i}</label>
					</div>
				</div>
			);
		});
	};
	render() {
		return (
			<div className="ui form">
				<Header as="h2" color="grey" textAlign="center">
					Tell us about your areas of interest
				</Header>
				<Form.Group inline>
					<label>Please select 3</label>
				</Form.Group>
				{this.areasOfInterest()}
			</div>
		);
	}
}
