import React from "react";
import styled from "styled-components";
import { Block } from "../../../app/common/styledComponents/Elements";
import CentreList from "./CentreList";
//import NewCentre from "./NewCentre";

/* const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 1em;
`; */
const CentreBlock = styled(Block)`
	border: 1px solid #dedede;
	padding: 1em;
`;

export default function ManageCentres({ firebase }) {
	return (
		<div>
			<CentreBlock>
				<CentreList firebase={firebase}></CentreList>
			</CentreBlock>
			{/* <Grid>
				 <CentreBlock>
					<NewCentre firebase={firebase}></NewCentre>
				</CentreBlock> 
			</Grid> */}
		</div>
	);
}
