import React from "react";
import {
	Block,
	SeeAllLink
} from "../../../app/common/styledComponents/Elements";
import { Header, Divider } from "semantic-ui-react";
import DocumentTable from ".";

/* const FilterGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 1em;
	row-gap: 2em;
	margin: 2em 0 0;
	align-items: center;
	.ui.slider.checkbox .box,
	.ui.slider.checkbox label {
		color: rgba(0, 0, 0, 0.8);
	}
	span {
		grid-column: 1 / 3 span;
		align-self: center;
		.inline.fields {
			margin: 0 !important;
		}
	}
`; */

export default function DocumentTableBlock({ firebase }) {
	return (
		<Block>
			<Header as="h3" dividing style={{ textTransform: "uppercase" }}>
				Favourite documents
			</Header>

			<DocumentTable
				firebase={firebase}
				showFavouriteValue={true}
			></DocumentTable>
			<Divider />
			<SeeAllLink to="/documents">See all documents</SeeAllLink>
		</Block>
	);
}
