import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
	Container,
	Header,
	Icon,
	Divider,
	Form,
	Input
} from "semantic-ui-react";
import {
	AppContainer,
	HeaderContainer,
	DocumentGrid
} from "../../app/common/styledComponents/Layout";
import { Block, EmptyState } from "../../app/common/styledComponents/Elements";
import Axios from "axios";
import DiscussionCard from "./DiscussionCard";
import styled from "styled-components";
import matchSorter from "match-sorter";
import { Ring } from "react-awesome-spinners";

import posed, { PoseGroup } from "react-pose";

const PosedCard = posed.div({
	enter: { opacity: 1, delay: ({ position }) => position * 100 },
	exit: { opacity: 0 }
});

const Discussion = () => {
	const [discussions, setDiscussions] = useState([]);
	const [searchValue, setSearch] = useState("");
	const [filterValue, setFilter] = useState("");
	const [allCategories, setAllCategories] = useState([]);
	const [isLoading, setLoading] = useState(true);

	const [docConfig, setDocConfig] = useState({
		posts: [],
		baseUrl: `${process.env.REACT_APP_WP_API}/discussions`,
		perPage: "?per_page=100"
	});

	// Get all of the discussions from Wordpress
	useEffect(() => {
		async function getNumPages(term) {
			const { headers } = await Axios(
				`${docConfig.baseUrl}${docConfig.perPage}`
			);
			return headers["x-wp-totalpages"];
		}
		async function fetchDiscussions(numPages) {
			const posts = [];
			for (let page = 1; page <= numPages; page += 1) {
				const post = Axios.get(
					`${docConfig.baseUrl}${docConfig.perPage}&page=${page}`
				);
				posts.push(post);
			}
			await Axios.all(posts)
				.then(response => {
					const postData = response.map(res => res.data).flat();

					setDiscussions(prev => [...prev, ...postData]);
					setLoading(false);
				})
				.catch(e => console.log("error fetching posts: ", e));
			return true;
		}

		const fetchData = async () => {
			setLoading(true);
			getNumPages().then(numPages => fetchDiscussions(numPages));
		};
		fetchData();
	}, []);

	// Get all of the discussion categories from Wordpress
	useEffect(() => {
		const fetchData = async () => {
			const result = await Axios(
				`${process.env.REACT_APP_WP_API}/categories_discussions`
			);
			setAllCategories(result.data);
		};
		fetchData();
	}, []);

	// Create options for the dropdown list
	const CategoryOptions = () => {
		return allCategories.map(cat => {
			return (
				<option key={cat.id} value={cat.id}>
					{cat.name}
				</option>
			);
		});
	};

	const filteredData = matchSorter(discussions, searchValue, {
		keys: [d => d.title.rendered]
	}).filter(d => {
		if (filterValue === "") {
			return d;
		}
		return d.categories_discussions.some(cat => {
			return cat.toString() === filterValue;
		});
	});

	return (
		<AppContainer>
			<Container>
				<HeaderContainer>
					<Header
						as="h2"
						color="orange"
						style={{ textTransform: "uppercase" }}
					>
						<Icon name="newspaper" size="mini" />
						<Header.Content>Discussions</Header.Content>
					</Header>
				</HeaderContainer>
				<Block>
					<DiscussionTopBlock>
						<div>
							<Form>
								<Form.Field>
									<label>Search</label>
									<Input
										icon="search"
										value={searchValue || ""}
										onChange={e => {
											setSearch(e.target.value || "");
											// Set undefined to remove the filter entirely
										}}
										placeholder={`Search ${discussions.length} discussions...`}
									/>
								</Form.Field>
							</Form>
						</div>
						<div>
							<Form>
								<Form.Field>
									<label>Filter</label>
									<select
										name="category"
										onChange={e => {
											setFilter(e.target.value || "");
										}}
										value={filterValue || ""}
										placeholder={`Filter ${discussions.length} discussions...`}
									>
										<option
											className="filterOption"
											defaultValue
											value=""
										>
											Filter by category
										</option>
										<CategoryOptions />
									</select>
								</Form.Field>
							</Form>
						</div>

						<div className="buttonBox">
							<ButtonLink to="/discussions/create">
								New Discussion
							</ButtonLink>
						</div>
					</DiscussionTopBlock>

					<Divider />
					<DocContainer>
						{!isLoading ? (
							<DocumentGrid>
								{" "}
								<PoseGroup
									animateOnMount={true}
									flipMove={false}
								>
									{filteredData.length > 0 ? (
										filteredData.map((d, idx) => (
											<PosedCard
												key={d.id}
												position={idx}
											>
												<DiscussionCard
													discussion={d}
													key={d.id}
													categories={
														d.categories_discussions
													}
												/>
											</PosedCard>
										))
									) : (
										<PosedCard key="empty" position={0}>
											<EmptyState
												style={{
													gridColumn: "1 / 3 span"
												}}
											>
												<Icon
													size="big"
													name="comment outline"
												/>
												<Header as="h3">
													No discussions match your
													search
												</Header>
											</EmptyState>
										</PosedCard>
									)}
								</PoseGroup>
							</DocumentGrid>
						) : (
							<Ring color="#EB5B28" />
						)}
					</DocContainer>
				</Block>
			</Container>
		</AppContainer>
	);
};

export default Discussion;

const DocContainer = styled.span`
	display: grid;
	justify-items: center;
`;

const ButtonLink = styled(NavLink)`
	background-color: #eb5b28;
	color: #fff;
	box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
	/* float: right; */
	margin-right: 0;
	margin-left: 0.25em;
	font-size: 16px;
	font-weight: 600;
	padding: 10px 20px;
	border-radius: 4px;
	margin-bottom: 20px;
	transition: all 0, 2s ease;
	:hover {
		background-color: #e84910;
		color: #fff;
	}
`;

const DiscussionTopBlock = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 30px;
	.buttonBox {
		text-align: right;
		margin-top: 30px;
	}
	select {
		height: 38px !important;
		color: rgba(0, 0, 0, 0.25) !important;
		font-size: 15px !important;
	}
	@media (max-width: 800px) {
		grid-template-columns: 1fr;
	}
`;
