import React from "react";
import styled from "styled-components";
import NavigationMobile from "./navigationMobile";

const Drawer = styled.div`
	background-color: #444;
	color: #fff;
	position: fixed;
	overflow: auto;
	z-index: 9999;
	left: 0;
	top: 0;
	width: 100vw;
	max-height: 100vh;
	overflow: hidden;
	height: 100vh;
	transition: all 0.2s cubic-bezier(0.19, 0.61, 1, 0.31);
	.nav-wrapper {
		width: 70vw;
	}
	&.closed {
		max-height: 0;
		overflow: hidden;
	}
	&.open {
		display: block;
		max-height: 100vh;
	}
	img {
		position: absolute;
		opacity: 0.2;
		right: -50%;
		bottom: -50%;
	}
`;

const NavigationDrawer = ({ isOpen, toggle }) => {
	const classes = ["main-nav"];
	if (isOpen) {
		classes.push("open");
	} else classes.push("closed");

	console.log("ISOPEN", isOpen);

	return (
		<Drawer className={classes.join(" ")}>
			<NavigationMobile toggle={toggle} />
		</Drawer>
	);
};

export default NavigationDrawer;
