import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Block } from "../styledComponents/Elements";
import { AppContainer } from "../styledComponents/Layout";
import { Container, Button, Image } from "semantic-ui-react";
import { useUserContext } from "../../App";
import { Ring } from "react-awesome-spinners";

function Error404({ location }) {
	const { userData } = useUserContext();
	return (
		<AppContainer>
			<Container textAlign="center">
				{userData ? (
					<>
						<Block>
							<Image src="/not_found.svg" size="large" centered />
							<h2>You do not have access to this page</h2>
							<h3>
								Please contact Botshabelo to purchase or renew
								your licence
							</h3>
							<NavLink to="/dashboard">
								<Button color="orange">Go to dashboard</Button>
							</NavLink>
						</Block>
					</>
				) : (
					<Ring color="#EB5B28" />
				)}
			</Container>
		</AppContainer>
	);
}

export default withRouter(Error404);
