import React from "react";
import styled from "styled-components";

const Label = styled.div`
	padding: 0.4em 0.5em;
	border-radius: 15px;
	background-color: #a9a9a9;
	color: white;
	font-weight: 600;
	text-align: center;
	align-self: center;
	min-width: 60%;
	text-transform: capitalize;
`;

export default function RoleLabel({ role }) {
	return <Label>{role ? role : "-"}</Label>;
}
