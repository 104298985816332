import React, { useState, useEffect } from 'react';
import { Button, Container, Header, Icon } from 'semantic-ui-react';
import {
	AppContainer,
	HeaderContainer
} from '../../app/common/styledComponents/Layout';
import { Block } from '../../app/common/styledComponents/Elements';
import Axios from 'axios';
import styled from 'styled-components';
import { useUserContext } from '../../app/App';
import { NavLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Field, Form } from 'react-final-form';
import TextInput from '../../app/common/form/AuthTextInput';

const required = value => (value ? undefined : 'Required');

const DiscussionForm = () => {
	const [categoryIds, setCategoryIds] = useState([]);

	const { userData } = useUserContext();
	const { addToast } = useToasts();

	const [error, setError] = useState();

	// Get the discussion categories' ids from Wordpress
	useEffect(() => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: 'Basic ZXZhbjpQQjVNcTdFb2lvbElEWFhKUzdTM0l5ZFA=',
			'Access-Control-Allow-Origin': '*'
		};
		Axios.get(`${process.env.REACT_APP_WP_API}/categories_discussions`).then(
			res => {
				setCategoryIds(res.data);
			}
		);
	}, []);

	// Create options for the dropdown list
	const CategoryOptions = () => {
		return categoryIds.map(cat => {
			return (
				<option key={cat.id} value={cat.id}>
					{cat.name}
				</option>
			);
		});
	};

	// Post newly created discussion to Wordpress
	const onSubmit = values => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: 'Basic ZXZhbjpQQjVNcTdFb2lvbElEWFhKUzdTM0l5ZFA='
		};
		const body = {
			title: values.title.value,
			status: 'publish',
			content: values.description.value,
			created_by_key:
				(userData && userData.displayName) || (userData && userData.email),
			categories_discussions: values.category
		};

		Axios.post(`${process.env.REACT_APP_WP_API}/discussions`, body, {
			headers: headers
		})
			.then(res => {
				addToast('Created Successfully', {
					appearance: 'success',
					autoDismiss: true,
					autoDismissTimeout: 1500
				});
				setTimeout(() => {
					window.location.replace('/discussions');
				}, 1500);
			})
			.catch(function(error) {
				console.error('Error', error);
				setError(error);
			});
	};

	// PB5M q7Eo iolI DXXJ S7S3 IydP
	// Basic ZXZhbjpQQjVNcTdFb2lvbElEWFhKUzdTM0l5ZFA=

	return (
		<AppContainer>
			<Container>
				<HeaderContainer>
					<Header as="h2" color="orange" style={{ textTransform: 'uppercase' }}>
						<Icon name="newspaper" size="mini" />
						<Header.Content>Discussions</Header.Content>
					</Header>
					<div className="backButtonBox">
						<BackButton to="/discussions">Back to Discussions</BackButton>
					</div>
				</HeaderContainer>
				<Block>
					<Header as="h2" color="grey" textAlign="center">
						Start a new discussion
					</Header>
					<FormBlock>
						<Form
							onSubmit={onSubmit}
							render={({
								handleSubmit,
								form,
								submitting,
								pristine,
								values
							}) => (
								<form onSubmit={handleSubmit}>
									<FormBlock>
										<div className="ui form">
											<Field
												name="title"
												validate={required}
												fluid
												// placeholder="example@mail.com"
												label="Title"
												component={TextInput}
											/>
											<Field
												name="description"
												validate={required}
												fluid
												// placeholder="example@mail.com"
												label="Description"
												component={TextInput}
											/>
											<InputBox>
												<label>Category</label>
												<Field
													name="category"
													component="select"
													validate={required}
												>
													<option />
													<CategoryOptions />
												</Field>
											</InputBox>
										</div>
										<ButtonBlock>
											{submitting ? (
												<Button
													floated="right"
													color="orange"
													size="large"
													type="submit"
													loading
												>
													Submit
												</Button>
											) : (
												<Button
													floated="right"
													color="orange"
													size="large"
													type="submit"
												>
													Submit
												</Button>
											)}
										</ButtonBlock>
										{error && (
											<div className="ui red message">
												<p>{error.message}</p>
											</div>
										)}
									</FormBlock>
								</form>
							)}
						/>

						{/* <div className='ui form'>
              <form onSubmit={onSubmit}>
                <InputBox>
                  <label>Title</label>
                  <input
                    type='text'
                    name='title'
                    onChange={handleChange}
                    value={formValues.title}
                    required
                  />
                </InputBox>
                <InputBox>
                  <label>Description</label>
                  <input
                    type='text'
                    name='description'
                    onChange={handleChange}
                    value={formValues.description}
                    required
                  />
                </InputBox>
                <InputBox>
                  <label>Category</label>
                  <select
                    name='category'
                    onChange={handleChange}
                    value={formValues.category}
                    required
                  >
                    <option defaultValue>Select your category</option>
                    <CategoryOptions />
                  </select>
                </InputBox>

                <ButtonBlock>
                  <div>
                    <Button color='orange' size='large' type='submit'>
                      Create Discussion
                    </Button>
                  </div>
                </ButtonBlock>
              </form>
            </div> */}
					</FormBlock>
				</Block>
			</Container>
		</AppContainer>
	);
};

export default DiscussionForm;

const InputBox = styled.div`
	margin: 0 0 1em;
	label {
		display: block;
		margin: 0 0 0.28571429rem 0;
		color: rgba(0, 0, 0, 0.87);
		font-size: 0.92857143em;
		font-weight: 700;
		text-transform: none;
	}
	input {
		line-height: 1.21428571em;
		padding: 0.67857143em 1em;
		font-size: 1em;
		background: #fff;
		border: 1px solid rgba(34, 36, 38, 0.15);
		color: rgba(0, 0, 0, 0.87);
		border-radius: 0.28571429rem;
		box-shadow: 0 0 0 0 transparent inset;
		transition: color 0.1s ease, border-color 0.1s ease;
		border-radius: 4px;
	}
	select {
		line-height: 1.21428571em;
		padding: 0.67857143em 1em;
		font-size: 1em;
		background: #fff;
		border: 1px solid rgba(34, 36, 38, 0.15);
		color: rgba(0, 0, 0, 0.87);
		border-radius: 0.28571429rem;
		box-shadow: 0 0 0 0 transparent inset;
		transition: color 0.1s ease, border-color 0.1s ease;
		border-radius: 4px;
		height: 40px !important;
	}
`;

const FormBlock = styled.div``;

const ButtonBlock = styled.div`
	display: grid;
	justify-content: end;
	margin-top: 2em;
`;

const BackButton = styled(NavLink)`
	background-color: transparent;
	border: 2px solid #767676;
	/* border-color: #f2711c; */
	color: #767676;
	box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
	/* float: right; */
	margin-right: 0;
	margin-left: 0.25em;
	font-size: 16px;
	font-weight: 600;
	padding: 10px 20px;
	border-radius: 4px;
	margin-bottom: 20px;
	transition: all 0, 2s ease;
	:hover {
		border-color: #f26201;
		background-color: #f26201;
		color: #fff;
	}
`;
